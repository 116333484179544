import {
	LOADING_FAQ, LOADING_FAQ_SUCCESS, LOADING_FAQ_FAIL,
} from "@/const";

const initialState = {
	faqs: [],
	isLoading: false,
};

export default function FaqReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_FAQ:
			return {
				...state,
				isLoading: true,
			};
		case LOADING_FAQ_SUCCESS: {
			return {
				...state,
				faqs: action.data,
				isLoading: false,
			};
		}
		case LOADING_FAQ_FAIL: {
			return {
				...state,
				faqs: [],
				isLoading: false,
			};
		}
		default:
			return state;
	}
}
