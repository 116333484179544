export const LOADING_JOB_AUTO = "LOADING_JOB_AUTO";
export const LOADING_JOB_AUTO_SUCCESS = "LOADING_JOB_AUTO_SUCCESS";
export const LOADING_JOB_AUTO_FAIL = "LOADING_JOB_AUTO_FAIL";

/**
 * JOB GENERAL CLEANING
 */

export const LOADING_JGC = "LOADING_JGC";
export const LOADING_JGC_SUCCESS = "LOADING_JGC_SUCCESS";
export const LOADING_JGC_FAIL = "LOADING_JGC_FAIL";

export const CREATE_SERVEY_JGC = "CREATE_SERVEY_JGC";
export const CREATE_JGC = "CREATE_JGC";
export const ASSIGN_JOB_JGC = "ASSIGN_JOB_JGC";
export const DELETE_JGC = "DELETE_JGC";

/**
 * JOB OTHER
 */

export const LOADING_JO = "LOADING_JO";
export const LOADING_JO_SUCCESS = "LOADING_JO_SUCCESS";
export const LOADING_JO_FAIL = "LOADING_JO_FAIL";

export const CREATE_SERVEY_JO = "CREATE_SERVEY_JO";
export const CREATE_JO = "CREATE_JO";
export const ASSIGN_JOB_JO = "ASSIGN_JOB_JO";
export const DELETE_JO = "DELETE_JO";

/**
 * JOB PERIODIC
 */

export const LOADING_JP = "LOADING_JP";
export const LOADING_JP_SUCCESS = "LOADING_JP_SUCCESS";
export const LOADING_JP_FAIL = "LOADING_JP_FAIL";

export const CREATE_JP = "CREATE_JP";
export const ASSIGN_JOB_JP = "ASSIGN_JOB_JP";
export const DELETE_JP = "DELETE_JP";

/**
 * JOB SUDDEN
 */

export const LOADING_JS = "LOADING_JS";
export const LOADING_JS_SUCCESS = "LOADING_JS_SUCCESS";
export const LOADING_JS_FAIL = "LOADING_JS_FAIL";

export const CREATE_JS = "CREATE_JS";
export const ASSIGN_JOB_JS = "ASSIGN_JOB_JS";
export const DELETE_JS = "DELETE_JS";
