import {
	LOADING_JGC, LOADING_JO, LOADING_JP, LOADING_JS,
	CREATE_SERVEY_JGC, CREATE_SERVEY_JO,
	CREATE_JGC, CREATE_JO, CREATE_JP, CREATE_JS,
	ASSIGN_JOB_JGC, ASSIGN_JOB_JO, ASSIGN_JOB_JP, ASSIGN_JOB_JS,
	DELETE_JGC, DELETE_JO, DELETE_JP, DELETE_JS,
} from "@/const";
import API from "@/libs/api";
import { queryString } from "@/libs/common";

export const JGC = "/job-general-cleaning";
export const JO = "/job-other";
export const JP = "/job-periodic";
export const JS = "/job-sudden";

const getPathName = (type) => {
	let pathname;
	switch (type) {
		case LOADING_JGC:
		case ASSIGN_JOB_JGC:
		case CREATE_SERVEY_JGC:
		case CREATE_JGC:
		case DELETE_JGC:
			pathname = JGC;
			break;
		case LOADING_JO:
		case ASSIGN_JOB_JO:
		case CREATE_SERVEY_JO:
		case CREATE_JO:
		case DELETE_JO:
			pathname = JO;
			break;
		case LOADING_JP:
		case ASSIGN_JOB_JP:
		case CREATE_JP:
		case DELETE_JP:
			pathname = JP;
			break;
		case LOADING_JS:
		case ASSIGN_JOB_JS:
		case CREATE_JS:
		case DELETE_JS:
			pathname = JS;
			break;
		default:
			break;
	}

	return pathname;
};

export const getJob = (type, params) => API.get(`${getPathName(type)}/?${queryString(params)}`);

export const createSurveyRequestJob = (type, data) => API.post(`${getPathName(type)}/create-survey-request`, data);

export const createJob = (type, data) => API.post(`${getPathName(type)}/create`, data);

export const assignJob = (type, data) => API.post(`${getPathName(type)}/assign-job`, data);

export const removeJob = (type, id) => API.delete(`${getPathName(type)}/cancel/${id}`);

/**
 * Service pack
 */

export const getServicePack = (cateId) => API.get(`service-pack/?categoryId=${cateId}`);

export const getServicePackByCode = (code) => API.get(`service-pack/code?code=${code}`);

export const getServicePackByType = (data) => API.post("service-pack/type", data);

export const updateServicePack = (data) => API.put("service-pack/update-service-pack", data);

export const createServicePack = (data) => API.post("service-pack/create-service-pack", data);

export const removeServicePack = (id, data) => API.delete(`service-pack/remove-service-pack/${id}`, data);
