import API from "@/libs/api";
import { queryString } from "@/libs/common";

const basePath = "/voucher";

export const createVoucher = (data) => API.post(`${basePath}/create`, data);

export const updateVoucher = (id, data) => API.put(`${basePath}/update/${id}`, data);

export const deleteVoucher = (id) => API.delete(`${basePath}/delete/${id}`);

export const getAllVouchers = (params) => API.get(`${basePath}/all?${queryString(params)}`);

export const getDetailVouchers = (id) => API.get(`${basePath}/${id}`);

export const getAllVouchersOfCustomer = (params) => API.get(`${basePath}/vouchers-of-customer?${queryString(params)}`);

export const sendVoucherForCustomer = (data) => API.post(`${basePath}/send`, data);

export const getListRefNotication = (params) => API.get(`${basePath}/refferals?${queryString(params)}`);
