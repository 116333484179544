import {
	LOADING_COUPON, LOADING_COUPON_SUCCESS, LOADING_COUPON_FAIL,
	CREATE_COUPON, DELETE_COUPON, EDIT_COUPON, SEND_COUPON,
} from "@/const";
import { call, put, takeLatest } from "redux-saga/effects";
import {
	getAllVouchers, createVoucher, deleteVoucher, updateVoucher,
	sendVoucherForCustomer,
} from "@/api/voucher";
import { notification } from "antd";
import { history } from "@/configureStore";

function* fetchCoupons({ payload }) {
	try {
		const coupons = yield call(getAllVouchers, payload);
		if (coupons) {
			const { code, data } = coupons;
			if (code === 200) {
				yield put({ type: LOADING_COUPON_SUCCESS, data });
			} else {
				yield put({ type: LOADING_COUPON_FAIL });
			}
		}
	} catch (error) {
		console.log(error);
	}
}

function* createCoupon({ payload }) {
	try {
		const create = yield call(createVoucher, payload);
		console.log("🚀 ~ file: coupon.js ~ line 32 ~ function*createCoupon ~ create", create);

		if (create) {
			const { code, msg } = create;

			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else if (code === 417) {
				yield notification.error({
					// description: msg,
					message: "Mã Vourhcer này đã tồn tại",
				});
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Tạo khuyến mãi thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* editCoupon({ payload }) {
	try {
		const { id, body } = payload;
		const edit = yield call(updateVoucher, id, body);

		if (edit) {
			const { code, msg } = edit;

			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Sửa khuyến mãi thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* deleteCoupon({ payload }) {
	try {
		const del = yield call(deleteVoucher, payload);
		if (del) {
			const { code, msg } = del;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Xóa khuyến mãi thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* sendCoupon({ payload }) {
	try {
		const send = yield call(sendVoucherForCustomer, payload);
		if (send) {
			const { code, msg } = send;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Gửi khuyến mãi thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

export default function* couponSaga() {
	yield takeLatest(LOADING_COUPON, fetchCoupons);
	yield takeLatest(CREATE_COUPON, createCoupon);
	yield takeLatest(DELETE_COUPON, deleteCoupon);
	yield takeLatest(EDIT_COUPON, editCoupon);
	yield takeLatest(SEND_COUPON, sendCoupon);
}
