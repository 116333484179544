import {
	createSurveyRequestJob, createJob,
	getJob, assignJob, removeJob,
} from "@/api/automatic";
import { history } from "@/configureStore";
import {
	LOADING_JOB_AUTO_SUCCESS, LOADING_JOB_AUTO_FAIL,
	LOADING_JGC, LOADING_JO, LOADING_JP, LOADING_JS,
	CREATE_SERVEY_JGC, CREATE_SERVEY_JO,
	CREATE_JGC, CREATE_JO, CREATE_JP, CREATE_JS,
	ASSIGN_JOB_JGC, ASSIGN_JOB_JO, ASSIGN_JOB_JP, ASSIGN_JOB_JS,
	DELETE_JGC, DELETE_JO, DELETE_JP, DELETE_JS,
} from "@/const";
import { notification } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";

function* fetchJobError() {
	yield put({ type: LOADING_JOB_AUTO_FAIL });
	yield notification.error({
		message: "Thất bại",
		description: "Vui lòng kiểm tra lại đường truyền hoặc thử lại sau",
	});
}

function* fetchJobAuto({ type, payload }) {
	try {
		const jobAuto = yield call(getJob, type, payload);

		if (jobAuto) {
			const { code, data } = jobAuto;
			if (code === 200) {
				yield put({
					type: LOADING_JOB_AUTO_SUCCESS,
					payload: {
						data: data?.data || [],
						total: data?.pageInfo?.total || 0,
						page: payload.page,
						perPage: payload.limit,
					},

				});
			} else {
				yield fetchJobError();
			}
		}
	} catch (error) {
		yield fetchJobError();
	}
}

function* createSurvey({ payload, type }) {
	try {
		const create = yield call(createSurveyRequestJob, type, payload);
		console.log(create);
		if (create) {
			const { code, msg } = create;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
				yield history.goBack();
			} else {
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Thất bại",
			description: error?.toString(),
		});
	}
}

function* createJobAuto({ type, payload }) {
	try {
		const create = yield call(createJob, type, payload);
		console.log(type);
		if (create) {
			const { code, msg } = create;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
				yield history.goBack();
			} else {
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Thất bại",
			description: error?.toString(),
		});
	}
}

function* assignJobAuto({ type, payload }) {
	try {
		const assign = yield call(assignJob, type, payload);
		if (assign) {
			const { code, msg } = assign;
			console.log(assign);
			if (code === 200) {
				yield notification.success({
					message: "Giao việc thành công",
					description: msg,
				});
				yield history.goBack();
			} else {
				yield notification.error({
					message: "Giao việc thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Giao việc thất bại",
			description: error?.toString(),
		});
	}
}

function* deleteJob({ type, payload }) {
	try {
		const remove = yield call(removeJob, type, payload);

		if (remove?.code === 200) {
			yield history.goBack();
		} else {
			yield notification.error({
				message: "Xóa thất bại",
				description: remove?.msg,
			});
		}
	} catch (error) {
		yield notification.error({
			message: "Xóa thất bại",
			description: error?.toString(),
		});
	}
}

export default function* automaticSaga() {
	// loading all job
	// yield takeLatest(LOADING_JOB_AUTO, fetchAllJob);

	// loading job by type
	yield takeLatest(LOADING_JGC, fetchJobAuto);
	yield takeLatest(LOADING_JO, fetchJobAuto);
	yield takeLatest(LOADING_JP, fetchJobAuto);
	yield takeLatest(LOADING_JS, fetchJobAuto);

	// create survey
	yield takeLatest(CREATE_SERVEY_JGC, createSurvey);
	yield takeLatest(CREATE_SERVEY_JO, createSurvey);

	// create
	yield takeLatest(CREATE_JGC, createJobAuto);
	yield takeLatest(CREATE_JO, createJobAuto);
	yield takeLatest(CREATE_JP, createJobAuto);
	yield takeLatest(CREATE_JS, createJobAuto);

	// assign
	yield takeLatest(ASSIGN_JOB_JGC, assignJobAuto);
	yield takeLatest(ASSIGN_JOB_JO, assignJobAuto);
	yield takeLatest(ASSIGN_JOB_JP, assignJobAuto);
	yield takeLatest(ASSIGN_JOB_JS, assignJobAuto);

	// delete
	yield takeLatest(DELETE_JGC, deleteJob);
	yield takeLatest(DELETE_JO, deleteJob);
	yield takeLatest(DELETE_JP, deleteJob);
	yield takeLatest(DELETE_JS, deleteJob);
}
