import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware, { END } from "redux-saga";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

import { persistReducer, persistStore } from "redux-persist";
import createRootReducer from "@/reducers";
import rootSaga from "@/saga";

import enMessage from "../languages/en.json";

const defaultStore = {
	intl: {
		locale: "en",
		messages: enMessage,
	},
};

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
	key: "root",
	storage,
	stateReconciler: autoMergeLevel2,
};
const pReducer = persistReducer(persistConfig, createRootReducer(history));

const store = createStore(
	pReducer,
	defaultStore,
	compose(
		applyMiddleware(routerMiddleware(history)),
		applyMiddleware(sagaMiddleware),
	),
);

store.runSaga = sagaMiddleware.run;
store.close = () => store.dispatch(END);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
