import API from "@/libs/api";
import { queryString } from "@/libs/common";

const basePath = "/intro";

export const createVideo = (data) => API.post(`${basePath}/video/create`, data);

export const editVideo = (id, data) => API.put(`${basePath}/video/edit/${id}`, data);

export const deleteVideo = (id) => API.delete(`${basePath}/video/delete/${id}`);

export const getVideo = (params) => API.get(`${basePath}/video?${queryString(params)}`);

export const createQa = (data) => API.post(`${basePath}/qa/create`, data);

export const editQa = (id, data) => API.put(`${basePath}/qa/edit/${id}`, data);

export const deleteQa = (id) => API.delete(`${basePath}/qa/delete/${id}`);

export const getQa = (params) => API.get(`${basePath}/qa?${queryString(params)}`);

export const getVideoCategory = (params) => API.get(`${basePath}/video-category?${queryString(params)}`);

export const createVideoCategory = (data) => API.post(`${basePath}/video-category/create`, data);

export const updateVideoCategory = (data, id) => API.put(`${basePath}/video-category/update/${id}`, data);

export const deleteVideoCategory = (id) => API.delete(`${basePath}/video-category/delete/${id}`);

export const getVideoInCategory = (params) => API.post(`${basePath}/video-in-category?${queryString(params)}`);
