export const VIEW_NOTICE = "VIEW_NOTICE";
export const ADD_NOTICE = "ADD_NOTICE";
export const EDIT_NOTICE = "EDIT_NOTICE";
export const SEND_NOTICE = "SEND_NOTICE";
export const DELETE_NOTICE = "DELETE_NOTICE";

export const VIEW_NOTICE_GROUP = "VIEW_NOTICE_GROUP";
export const DELETE_NOTICE_GROUP = "DELETE_NOTICE_GROUP";
export const ADD_NOTICE_GROUP = "ADD_NOTICE_GROUP";
export const EDIT_NOTICE_GROUP = "EDIT_NOTICE_GROUP";

export const SUBSCRIBE_NOTICE = "SUBSCRIBE_NOTICE";
export const UNSUBSCRIBE_NOTICE = "UNSUBSCRIBE_NOTICE";

export const LOADING_NOTIFICATION = "LOADING_NOTIFICATION";
export const LOADING_NOTIFICATION_SUCCESS = "LOADING_NOTIFICATION_SUCCESS";
export const LOADING_NOTIFICATION_FAIL = "LOADING_NOTIFICATION_FAIL";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAIL = "CREATE_NOTIFICATION_FAIL";

export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
export const EDIT_NOTIFICATION_SUCCESS = "EDIT_NOTIFICATION_SUCCESS";
export const EDIT_NOTIFICATION_FAIL = "EDIT_NOTIFICATION_FAIL";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";

export const SUBSCRIBE_NOTIFICATION = "SUBSCRIBE_NOTIFICATION";
export const SUBSCRIBE_NOTIFICATION_SUCCESS = "SUBSCRIBE_NOTIFICATION_SUCCESS";
export const SUBSCRIBE_NOTIFICATION_FAIL = "SUBSCRIBE_NOTIFICATION_FAIL";

export const UNSUBSCRIBE_NOTIFICATION = "UNSUBSCRIBE_NOTIFICATION";
export const UNSUBSCRIBE_NOTIFICATION_SUCCESS = "UNSUBSCRIBE_NOTIFICATION_SUCCESS";
export const UNSUBSCRIBE_NOTIFICATION_FAIL = "UNSUBSCRIBE_NOTIFICATION_FAIL";

export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAIL = "SEND_NOTIFICATION_FAIL";

export const LOADING_NOTIFICATION_GROUP = "LOADING_NOTIFICATION_GROUP";
export const LOADING_NOTIFICATION_GROUP_SUCCESS = "LOADING_NOTIFICATION_GROUP_SUCCESS";
export const LOADING_NOTIFICATION_GROUP_FAIL = "LOADING_NOTIFICATION_GROUP_FAIL";

export const CREATE_NOTIFICATION_GROUP = "CREATE_NOTIFICATION_GROUP";
export const CREATE_NOTIFICATION_GROUP_SUCCESS = "CREATE_NOTIFICATION_GROUP_SUCCESS";
export const CREATE_NOTIFICATION_GROUP_FAIL = "CREATE_NOTIFICATION_GROUP_FAIL";

export const EDIT_NOTIFICATION_GROUP = "EDIT_NOTIFICATION_GROUP";
export const EDIT_NOTIFICATION_GROUP_SUCCESS = "EDIT_NOTIFICATION_GROUP_SUCCESS";
export const EDIT_NOTIFICATION_GROUP_FAIL = "EDIT_NOTIFICATION_GROUP_FAIL";

export const DELETE_NOTIFICATION_GROUP = "DELETE_NOTIFICATION_GROUP";
export const DELETE_NOTIFICATION_GROUP_SUCCESS = "DELETE_NOTIFICATION_GROUP_SUCCESS";
export const DELETE_NOTIFICATION_GROUP_FAIL = "DELETE_NOTIFICATION_GROUP_FAIL";

export const DELETE_NOTIFICATION_BATCH = "DELETE_NOTIFICATION_BATCH";
