import React, {
	memo, useEffect, useRef, useState,
} from "react";
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import { BackTop, Layout, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import CustomBreadcrumb from "@components/Breadcrum";
import { Link, withRouter } from "react-router-dom";
import MenuUser from "@/layouts/Header/MenuUser";
import { history } from "@/configureStore";
import { menus } from "@/libs/common";
import { disabledScrollAction, logoutAction } from "@/actions/auth";
import { GENERAL } from "@/const";

const {
	Header, Content, Sider, Footer,
} = Layout;
const { SubMenu } = Menu;

const BasicLayout = ({ children }) => {
	const dispatch = useDispatch();

	const [collapsed, setCollapsed] = useState(false);

	const { canScroll, profile, permissions } = useSelector((state) => state.auth);

	const onCollapse = (collapse) => setCollapsed(collapse);

	const layoutRef = useRef(null);

	const scrollIntoView = () => {
		if (layoutRef?.current && canScroll) {
			setTimeout(() => {
				layoutRef.current?.scrollIntoView?.({ behavior: "smooth" });
				dispatch(disabledScrollAction());
			}, 500);
		}
	};

	useEffect(() => {
		if (canScroll) {
			scrollIntoView();
		}
	}, [canScroll]);

	const handleHideSidebar = () => {
		const { width } = window.screen;
		if (width <= 414 && !collapsed) {
			onCollapse(true);
		}
	};

	const onClickMenuItem = (url) => {
		history.push(url, { initPage: 1 });
		handleHideSidebar();
	};

	const logout = () => dispatch(logoutAction());

	return (
		<div ref={layoutRef}>
			<Layout className="basic-layout">
				<Sider
					collapsible
					collapsed={collapsed}
					onCollapse={onCollapse}
					className="sider"
					breakpoint="lg"
					collapsedWidth="0"
				>
					<Link to="/">
						<div className="logo" />
					</Link>

					<Menu
						theme="dark"
						mode="inline"
						defaultOpenKeys={["/users", "/services"]}
					>
						{menus
							.filter(({ keyName }) => Array.isArray(permissions)
							&& (permissions.includes(keyName) || keyName.includes(GENERAL)))
							.map(({
								path, icon, label, routes,
							}) => {
								if (routes?.length) {
									return (
										<SubMenu
											key={path}
											title={label}
											icon={icon}
										>
											{routes
												.filter(({ keyName }) => Array.isArray(permissions)
												&& (permissions.includes(keyName)
												|| keyName.includes(GENERAL)))
												.map((rChild, i) => (
													<Menu.Item key={`${rChild.path}${i}`} onClick={() => onClickMenuItem(rChild.path)}>
														{rChild.label}
													</Menu.Item>
												))}
										</SubMenu>

									);
								}
								return (
									<Menu.Item key={path} onClick={() => onClickMenuItem(path)} icon={icon}>
										{label}
									</Menu.Item>
								);
							})}

						<Menu.Item key="logout" onClick={logout} icon={<LogoutOutlined />}>
							Đăng xuất
						</Menu.Item>
					</Menu>
				</Sider>

				<Layout className="site-layout">
					<Header className="site-layout-background header">
						<div className="header-right">
							<MenuUser user={profile} />
						</div>
					</Header>

					<Content onClick={handleHideSidebar}>
						<CustomBreadcrumb />
						{children}
					</Content>

					<Footer>
						<span>Powered by Twinger .JSC</span>
						<BackTop />
					</Footer>
				</Layout>

			</Layout>
		</div>
	);
};

const enhance = compose(withRouter, memo);

export default enhance(BasicLayout);
