import { Spin } from "antd";
import React, { lazy, Suspense } from "react";

const loadable = (importFunc) => {
	const LazyComponent = lazy(importFunc);

	return (props) => (
		<Suspense
			fallback={(
				<div className="loading">
					<Spin tip="Đang tải dữ liệu..." />
				</div>
			)}
		>
			<LazyComponent {...props} />
		</Suspense>
	);
};

export default loadable;
