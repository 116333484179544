// export const ENDPOINT = "http://localhost:8080/api/v1/admin";
// export const BASE_URL = "http://localhost.com:8080/";

// export const ENDPOINT = "http://sv.twinger.vn:8080/api/v1/admin";
// export const BASE_URL = "http://sv.twinger.vn:8080/";

// export const ENDPOINT = "https://api.agiupviec.vn/api/v1/admin";
// export const BASE_URL = "https://api.agiupviec.vn/";

export const ENDPOINT = "https://agv-api.dev.twinger.vn/api/v1/admin";
export const BASE_URL = "https://agv-api.dev.twinger.vn/";

export const PRIMARY_COLOR = "#97c93d";
export const TIMEOUT = 20000;

export const FOLDER_ID = "5f64860bd0f03a52e859b8f6";
export const ID_ADMIN = "5f6424e83b2ab54c10da99d3";
export const ADMINISTRATOR = "ADMINISTRATOR";
export const ID_ROLE = [
	"5f6427181a6f934bc482db97",
	"5f64273e1a6f934bc482db98",
	"5fa2819e772a86e9d0df5ee3",
];

export const MALE = "male";
export const FEMALE = "female";
export const OTHER = "other";

export const CLEANED = "CLEANED";
export const MOVED = "MOVED";

export const USERS = "USERS";
export const TOPIC = "TOPIC";
export const NONE = "NONE";
export const DELETE = "DELETE";

export const DEFAULT = "DEFAULT";
export const ASC = "ASC";
export const DESC = "DESC";
export const ID = "_id";
export const CREATE_AT = "createdAt";

export const ALL = "All";
export const ACTIVE = "Active";
export const INACTIVE = "Inactive";
export const PUBLIC = "Public";
export const PUBLISH = "Publish";
export const DRAFT = "Draft";
export const PENDING = "Pending";
export const SCHEDULE = "Schedule";
export const PASS = "Password";
export const PAID = "Paid";
export const SUCCESS = "Success";
export const ERROR = "Error";
export const FAILED = "Failed";

export const SUPER_ADMIN = "SA";
export const ADMIN = "A";
export const MANAGER = "M";
export const SUPPORTER = "S";

export const GENERAL = "GENERAL";

export const VIEW_ADMIN = "VIEW_ADMIN";
export const VIEW_PARTNER = "VIEW_PARTNER";
export const VIEW_CUSTOMER = "VIEW_CUSTOMER";
export const VIEW_SERVICE = "VIEW_SERVICE";
export const VIEW_SERVICE_SETTING = "VIEW_SERVICE_SETTING";
export const VIEW_VOUCHER = "VIEW_VOUCHER";
export const VIEW_REFERAL = "VIEW_REFERAL";
export const VIEW_NOTIFICATION = "VIEW_NOTIFICATION";
export const VIEW_SETTING = "VIEW_SETTING";
export const VIEW_ROLE = "VIEW_ROLE";
export const SET_PARTNER = "SET_PARTNER";
export const SET_CHARGE = "SET_CHARGE";
export const SET_STATUS_PARTNER = "SET_STATUS_PARTNER";
