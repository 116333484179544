import { notification } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
	LOGGING_IN, LOGGING_SUCCESS, LOGGING_OUT, LOGOUT_SUCCESS,
	REFRESH_TOKEN, REFRESH_TOKEN_SUCCESS,
	LOADING_PROFILE_SUCCESS, LOADING_PROFILE,
	EDIT_PROFILE, EDIT_PROFILE_SUCCESS, SET_PERMISSIONS,
} from "@/const";
import { history } from "@/configureStore";
import {
	info, updateProfile,
	login as postLogin,
	logout as postLogout,
	refreshToken as postRefreshToken,
	addDeviceTokensAdmin,
} from "@/api/account";

function* login(params) {
	try {
		const {
			payload: { values, onSuccess },
		} = params;
		const deviceToken = localStorage.getItem("deviceToken");
		const paramsDeviceId = {
			deviceId: deviceToken,
		};

		const auth = yield call(postLogin, values);

		const { code, msg, data } = auth;

		if (code === 200) {
			yield notification.success({
				message: "Đăng nhập",
				description: "Đăng nhập thành công",
			});
			yield put({ type: LOGGING_SUCCESS, data });
			yield onSuccess();
			yield history.push("/");
			const token = yield call(addDeviceTokensAdmin, paramsDeviceId);
			// console.log("🚀 ~ file: auth.js ~ line 41 ~ function*login ~ token", token);
		} else {
			yield notification.error({
				message: "Thất bại",
				description: msg.toString(),
			});
		}
	} catch (error) {
		yield notification.error({
			message: "Lỗi đăng nhập",
			description: "Đã có lỗi xảy ra với tài khoản hoặc đường truyền không ổn định. Thử lại sau !",
		});
	}
}

function* handleLogout() {
	yield put({ type: LOGOUT_SUCCESS });
	yield history.push("/login");
}

function* showErrorMsg() {
	yield notification.error({
		message: "Thất bại",
		description: "Đã có lỗi xảy ra với tài khoản hoặc đường truyền không ổn định. Thử lại sau !",
	});
}

function* logout() {
	try {
		const deviceId = localStorage.getItem("deviceToken");
		const params = {
			deviceId,
		};
		const quit = yield call(postLogout, params);
		if (quit?.code === 200) {
			yield handleLogout();
		} else {
			yield showErrorMsg();
		}
	} catch (error) {
		yield showErrorMsg();
	}
}

function* refreshToken(params) {
	try {
		const { payload: { body, onSuccess } } = params;
		const refresh = yield call(postRefreshToken, body);
		if (refresh) {
			const { data, code } = refresh;

			if (code === 200) {
				yield put({
					type: REFRESH_TOKEN_SUCCESS,
					newAccessToken: data?.token?.accessToken,
				});
				yield onSuccess();
			} else {
				yield handleLogout();
			}
		} else {
			yield handleLogout();
		}
	} catch (error) {
		yield handleLogout();
	}
}

function* fetchProfile() {
	try {
		const get = yield call(info);
		if (get) {
			const { data, code } = get;
			if (code === 200) {
				yield put({ type: LOADING_PROFILE_SUCCESS, data });
				yield put({ type: SET_PERMISSIONS, data: data?.role?.permissions });
			} else {
				yield notification.error({
					message: "Thất bại",
					description: "Tải thông tin cá nhân thất bại, thử lại sau",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Thất bại",
			description: "Tải thông tin cá nhân thất bại, thử lại sau",
		});
	}
}

function* editProfile({ payload }) {
	try {
		const edit = yield call(updateProfile, payload);

		console.log("edit :>> ", edit);
		if (edit) {
			const { code, data } = edit;

			if (code === 200) {
				yield put({ type: EDIT_PROFILE_SUCCESS, data });
				yield notification.success({
					message: "Thành công",
					description: "Cập nhật thông tin cá nhân thành công",
				});
			} else {
				yield notification.success({
					message: "Thất bại",
					description: "Cập nhật thông tin cá nhân thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.success({
			message: "Thất bại",
			description: "Cập nhật thông tin cá nhân thất bại",
		});
	}
}

export default function* authSaga() {
	yield takeLatest(LOGGING_IN, login);
	yield takeLatest(LOGGING_OUT, logout);
	yield takeLatest(REFRESH_TOKEN, refreshToken);
	yield takeLatest(LOADING_PROFILE, fetchProfile);
	yield takeLatest(EDIT_PROFILE, editProfile);
}
