import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { loadingRoleCurrentAction } from "@/actions/role";
import { fetchProfileAction } from "@/actions/auth";

const LoadingApp = ({ loaded, children }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	const { token } = useSelector((state) => state.auth);

	useEffect(() => {
		if (loaded && token) {
			Promise.all([
				dispatch(fetchProfileAction()),
				// dispatch(loadingRoleCurrentAction({ isLoadingPageOption: true })),
			]);
		}
		setLoading(false);
	}, []);

	if (loading) return <div className="loading"><Spin tip="Đang tải dữ liệu..." /></div>;

	return children;
};

export default memo(LoadingApp);
