import { LOADING_PARTNER } from "@/const";

const initialState = {
	partners: [],
	page: 1,
	perPage: 10,
	total: 0,
	isLoading: false,
};

export default function PartnerReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_PARTNER:
			return {
				...state,
				isLoading: true,
			};
		default:
			return state;
	}
}
