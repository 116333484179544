export const VIEW_PAYMENT = "VIEW_PAYMENT";
export const ADD_PAYMENT = "ADD_PAYMENT";

export const LOADING_PAYMENT = "LOADING_PAYMENT";
export const LOADING_PAYMENT_SUCCESS = "LOADING_PAYMENT_SUCCESS";
export const LOADING_PAYMENT_FAIL = "LOADING_PAYMENT_FAIL";

export const LOADING_PAYMENT_CURRENT = "LOADING_PAYMENT_CURRENT";
export const LOADING_PAYMENT_CURRENT_SUCCESS = "LOADING_PAYMENT_CURRENT_SUCCESS";
export const LOADING_PAYMENT_CURRENT_FAIL = "LOADING_PAYMENT_CURRENT_FAIL";

export const EDIT_PAYMENT = "EDIT_PAYMENT";
export const EDIT_PAYMENT_SUCCESS = "EDIT_PAYMENT_SUCCESS";
export const EDIT_PAYMENT_FAIL = "EDIT_PAYMENT_FAIL";

export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_FAIL = "DELETE_PAYMENT_FAIL";

export const DELETE_PAYMENT_BATCH = "DELETE_PAYMENT_BATCH";
