export const LOADING_USER = "LOADING_USER";
export const LOADING_USER_SUCCESS = "LOADING_USER_SUCCESS";
export const LOADING_USER_FAIL = "LOADING_USER_FAIL";

export const VIEW_USER = "VIEW_USER";
export const ADD_USER = "ADD_USER";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const POST_USER_TAGS = "POST_USER_TAGS";

export const DELETE_USER_BATCH = "DELETE_USER_BATCH";

export const EDIT_PARTNER = "EDIT_USER";
export const EDIT_PARTNER_SUCCESS = "EDIT_PARTNER_SUCCESS";
export const EDIT_PARTNER_FAIL = "EDIT_PARTNER_FAIL";

/**
|--------------------------------------------------
| 5-1
|--------------------------------------------------
*/
export const LOADING_CUSTOMER = "LOADING_CUSTOMER";
export const LOADING_CUSTOMER_SUCCESS = "LOADING_CUSTOMER_SUCCESS";
export const LOADING_CUSTOMER_FAIL = "LOADING_CUSTOMER_FAIL";

export const LOADING_ACCOUNT = "LOADING_ACCOUNT";
export const LOADING_ACCOUNT_SUCCESS = "LOADING_ACCOUNT_SUCCESS";
export const LOADING_ACCOUNT_FAIL = "LOADING_ACCOUNT_FAIL";

export const LOADING_PARTNER = "LOADING_PARTNER";
export const LOADING_PARTNER_SUCCESS = "LOADING_PARTNER_SUCCESS";
export const LOADING_PARTNER_FAIL = "LOADING_PARTNER_FAIL";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAIL = "CREATE_ACCOUNT_FAIL";

export const CHANGE_STATUS_ACCOUNT = "CHANGE_STATUS_ACCOUNT";
export const CHANGE_STATUS_ACCOUNT_SUCCESS = "CHANGE_STATUS_ACCOUNT_SUCCESS";
export const CHANGE_STATUS_ACCOUNT_FAIL = "CHANGE_STATUS_ACCOUNT_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const SEARCH_CUSTOMER_SUCCESS = "SEARCH_CUSTOMER_SUCCESS";
export const SEARCH_CUSTOMER_FAIL = "SEARCH_CUSTOMER_SUCCESS";

export const SEARCH_PARTNER = "SEARCH_PARTNER";
export const SEARCH_PARTNER_SUCCESS = "SEARCH_PARTNER_SUCCESS";
export const SEARCH_PARTNER_FAIL = "SEARCH_PARTNER_FAIL";
