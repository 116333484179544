import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {
	LOGGING_IN, LOGGING_SUCCESS, SET_PERMISSIONS, LOGOUT_SUCCESS, GENERAL,
	SCROLLING, DISABLED_SCROLL, LOADING_PROFILE_SUCCESS, EDIT_PROFILE_SUCCESS,
	UPLOAD_AVATAR, REFRESH_TOKEN_SUCCESS,
} from "@/const";

const initialState = {
	isLoggedIn: false,
	token: null,
	refreshToken: null,
	permissions: [GENERAL],
	canScroll: false,
	profile: null,
};

export function AuthReducer(state = initialState, action) {
	switch (action.type) {
		case LOGGING_IN:
			return {
				...state,
			};
		case LOGGING_SUCCESS: {
			return {
				...state,
				token: action?.data?.token?.accessToken,
				isLoggedIn: !!action?.data?.token?.accessToken,
				refreshToken: action?.data?.refreshToken,
				profile: {
					...state.profile,
					uuid: action?.data?.uuid,
					username: action?.data?.username,
					email: action?.data?.email,
					avatar: action?.data?.avatar,
				},
			};
		}
		case LOGOUT_SUCCESS:
			return {
				...initialState,
			};
		case REFRESH_TOKEN_SUCCESS:
			return {
				...state,
				token: action.newAccessToken,
			};
		case SET_PERMISSIONS:
			return {
				...state,
				permissions: [GENERAL, ...action?.data],
			};
		case LOADING_PROFILE_SUCCESS:
			return {
				...state,
				profile: action.data,
			};
		case EDIT_PROFILE_SUCCESS: {
			return {
				...state,
				profile: {
					...state.profile,
					...action.data,
				},
			};
		}
		case UPLOAD_AVATAR:
			return {
				...state,
				profile: {
					...state.profile,
					avatar: action.payload,
				},
			};
		case SCROLLING:
			return {
				...state,
				canScroll: true,
			};
		case DISABLED_SCROLL:
			return {
				...state,
				canScroll: false,
			};
		default:
			return state;
	}
}
const persistConfig = {
	key: "auth",
	storage,
	blacklist: ["isLoggedIn"],
};

export default persistReducer(persistConfig, AuthReducer);
