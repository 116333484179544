import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { intlReducer } from "react-intl-redux";
import AuthReducer from "@/reducers/auth";
import UserReducer from "@/reducers/user";
import NotificationReducer from "@/reducers/notification";
import RoleReducer from "@/reducers/role";
import PaymentReducer from "@/reducers/payment";
import ServiceReducer from "@/reducers/service";
import AutomaticServiceReducer from "@/reducers/automatic";
import CouponReducer from "@/reducers/coupon";
import FaqReducer from "@/reducers/faq";
import ComplainReducer from "@/reducers/complain";
import EducateReducer from "@/reducers/educate";
import WithdrawalReducer from "@/reducers/withdrawal";
import HistoryReducer from "@/reducers/history";
import IntroductionReducer from "@/reducers/introduction";
import PartnerReducer from "@/reducers/partner";
import CategoryReducer from "@/reducers/category";

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	intl: intlReducer,
	auth: AuthReducer,
	users: UserReducer,
	notifications: NotificationReducer,
	roles: RoleReducer,
	payments: PaymentReducer,
	services: ServiceReducer,
	automatics: AutomaticServiceReducer,
	coupons: CouponReducer,
	faqs: FaqReducer,
	complains: ComplainReducer,
	educates: EducateReducer,
	withdrawals: WithdrawalReducer,
	histories: HistoryReducer,
	introduction: IntroductionReducer,
	partners: PartnerReducer,
	categories: CategoryReducer,
});

export default createRootReducer;
