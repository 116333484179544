import SubmitButton from "@components/SubmitButton";
import { Drawer, Form, Input } from "antd";
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { changePasswordAction } from "@/actions/user";

const ChangePassword = ({ visibleChangePw, onCloseChangePw }) => {
	const dispatch = useDispatch();

	const [form] = Form.useForm();

	const onFinish = ({ oldPassword, newPassword }) => {
		const params = {
			body: {
				oldPassword,
				newPassword,
			},
			onSuccess: async () => {
				await onCloseChangePw();
				setTimeout(() => {
					form.resetFields();
				}, 500);
			},
		};

		dispatch(changePasswordAction(params));
	};

	const resizeWindow = () => {
		const { width } = window.screen;

		if (width < 768) {
			return "100%";
		}
		return "40%";
	};

	return (
		<Drawer
			title="Đổi mật khẩu"
			width={resizeWindow()}
			onClose={onCloseChangePw}
			visible={visibleChangePw}
			className="change-pw"
		>
			<Form
				form={form}
				onFinish={onFinish}
				layout="vertical"
			>
				<Form.Item
					name="oldPassword"
					label="Mật khẩu cũ"
					rules={[{
						required: true,
						message: "Vui lòng nhập mật khẩu cũ",
					}]}
					hasFeedback
				>
					<Input.Password
						placeholder="Nhập mật khẩu cũ tại đây"
						size="large"
					/>
				</Form.Item>

				<Form.Item
					name="newPassword"
					label="Mật khẩu mới"
					rules={[{
						required: true,
						message: "Vui lòng nhập mật khẩu mới",
					}]}
					hasFeedback
				>
					<Input.Password
						placeholder="Nhập mật khẩu mới tại đây"
						size="large"
					/>
				</Form.Item>

				<Form.Item
					name="confirmPw"
					label="Xác nhận mật khẩu"
					dependencies={["newPassword"]}
					hasFeedback
					rules={[
						{
							required: true,
							message: "Vui lòng xác nhận lại mật khẩu mới",
						},
						({ getFieldValue }) => ({
							validator(_rule, value) {
								if (!value || getFieldValue("newPassword") === value) {
									return Promise.resolve();
								}
								// eslint-disable-next-line prefer-promise-reject-errors
								return Promise.reject("Mật khẩu không trùng khớp");
							},
						}),
					]}
				>
					<Input.Password
						placeholder="Xác nhận mật khẩu mới tại đây"
						size="large"
					/>
				</Form.Item>

				<SubmitButton
					block
					type="primary"
					onClick={() => form.submit()}
					className="change-pw__btn-change-pass"
					size="large"
				>
					Đổi mật khẩu
				</SubmitButton>
			</Form>
		</Drawer>
	);
};

export default memo(ChangePassword);
