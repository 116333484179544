export const VIEW_PARTNER = "VIEW_PARTNER";
export const ADD_PARTNER = "ADD_PARTNER";

export const CREATE_PARTNER = "CREATE_PARTNER";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";
export const CREATE_PARTNER_FAIL = "CREATE_PARTNER_FAIL";

// export const LOADING_PARTNER = "LOADING_PARTNER";
// export const LOADING_PARTNER_SUCCESS = "LOADING_PARTNER_SUCCESS";
// export const LOADING_PARTNER_FAIL = "LOADING_PARTNER_FAIL";

export const EDIT_PARTNER = "EDIT_PARTNER";
export const EDIT_PARTNER_SUCCESS = "EDIT_PARTNER_SUCCESS";
export const EDIT_PARTNER_FAIL = "EDIT_PARTNER_FAIL";

export const DELETE_PARTNER = "DELETE_PARTNER";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
export const DELETE_PARTNER_FAIL = "DELETE_PARTNER_FAIL";

export const DELETE_PARTNER_BATCH = "DELETE_PARTNER_BATCH";
