export const VIEW_CATEGORY = "VIEW_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";

export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";

export const LOADING_CATEGORY = "LOADING_CATEGORY";
export const LOADING_CATEGORY_SUCCESS = "LOADING_CATEGORY_SUCCESS";
export const LOADING_CATEGORY_FAIL = "LOADING_CATEGORY_FAIL";

export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_FAIL = "EDIT_CATEGORY_FAIL";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const DELETE_CATEGORY_BATCH = "DELETE_CATEGORY_BATCH";
