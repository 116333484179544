import { Breadcrumb } from "antd";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import routeConfig from "@/route/routeConfig";
import upperFirst from "lodash/upperFirst";
import { getRouteName } from "@/libs/common";

const CustomBreadcrumb = withRouter(({ location }) => {
	const pathSnippets = location.pathname.split("/").filter((i) => i);
	const matchRoute = getRouteName(routeConfig, pathSnippets);

	const extraBreadcrumbItems = pathSnippets.map((_, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
		const splitUrl = url.split("/");

		if (!matchRoute[index] && splitUrl.length > 3) {
			return null;
		}

		if (["settings"].includes(splitUrl[1]) && splitUrl.length === 2) {
			const name = upperFirst(splitUrl[1]);
			return (
				<Breadcrumb.Item key={`${name}-${url}`}>
					Cài đặt
				</Breadcrumb.Item>
			);
		}

		if (!matchRoute[index]) {
			return (
				<Breadcrumb.Item key={url}>
					Hiện tại
				</Breadcrumb.Item>
			);
		}

		return (
			<Breadcrumb.Item key={url}>
				<Link to={url}>{matchRoute[index]}</Link>
			</Breadcrumb.Item>
		);
	});

	const breadcrumbItems = [
		<Breadcrumb.Item key="home">
			<Link to="/">Trang chủ</Link>
		</Breadcrumb.Item>,
	].concat(extraBreadcrumbItems);

	return (
		<Breadcrumb>
			{breadcrumbItems}
		</Breadcrumb>
	);
});

export default CustomBreadcrumb;
