import {
	LOADING_NOTIFICATION, LOADING_NOTIFICATION_SUCCESS, LOADING_NOTIFICATION_FAIL,
	CREATE_NOTIFICATION, CREATE_NOTIFICATION_SUCCESS,
	EDIT_NOTIFICATION, EDIT_NOTIFICATION_SUCCESS,
	DELETE_NOTIFICATION, DELETE_NOTIFICATION_SUCCESS,
	LOADING_NOTIFICATION_GROUP, LOADING_NOTIFICATION_GROUP_SUCCESS, LOADING_NOTIFICATION_GROUP_FAIL,
	CREATE_NOTIFICATION_GROUP, CREATE_NOTIFICATION_GROUP_SUCCESS,
	EDIT_NOTIFICATION_GROUP, EDIT_NOTIFICATION_GROUP_SUCCESS,
	DELETE_NOTIFICATION_GROUP, DELETE_NOTIFICATION_GROUP_SUCCESS,
	SUBSCRIBE_NOTIFICATION, SUBSCRIBE_NOTIFICATION_FAIL, SUBSCRIBE_NOTIFICATION_SUCCESS,
	UNSUBSCRIBE_NOTIFICATION, UNSUBSCRIBE_NOTIFICATION_FAIL, UNSUBSCRIBE_NOTIFICATION_SUCCESS,
	SEND_NOTIFICATION, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_FAIL,
} from "@/const";

const initialState = {
	notifications: [],
	groups: [],
	page: 1,
	perPage: 10,
	total: 0,
	isLoading: false,
};

export default function NotificationReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_NOTIFICATION:
		case LOADING_NOTIFICATION_GROUP:
			return {
				...state,
				isLoading: false,
			};
		case CREATE_NOTIFICATION_GROUP:
		case EDIT_NOTIFICATION_GROUP:
		case EDIT_NOTIFICATION_GROUP_SUCCESS:
		case SUBSCRIBE_NOTIFICATION:
		case SUBSCRIBE_NOTIFICATION_FAIL:
		case SUBSCRIBE_NOTIFICATION_SUCCESS:
		case UNSUBSCRIBE_NOTIFICATION:
		case UNSUBSCRIBE_NOTIFICATION_FAIL:
		case UNSUBSCRIBE_NOTIFICATION_SUCCESS:
		case SEND_NOTIFICATION:
		case SEND_NOTIFICATION_SUCCESS:
		case SEND_NOTIFICATION_FAIL:
		case DELETE_NOTIFICATION_GROUP:
		case EDIT_NOTIFICATION:
		case EDIT_NOTIFICATION_SUCCESS:
		case DELETE_NOTIFICATION:
		case CREATE_NOTIFICATION:
			return {
				...state,
			};
		case LOADING_NOTIFICATION_SUCCESS: {
			const {
				notifications, page, perPage, total,
			} = action.data;

			return {
				...state,
				notifications,
				page: Number(page),
				perPage: Number(perPage),
				total,
				isLoading: false,
			};
		}
		case LOADING_NOTIFICATION_FAIL: {
			return {
				...state,
				page: Number(action.payload?.page) || 1,
				perPage: Number(action.payload?.perPage) || 10,
				total: action.payload?.total || 0,
				notifications: [],
				isLoading: false,
			};
		}
		case CREATE_NOTIFICATION_SUCCESS:
			return {
				...state,
				notifications: [...state.notifications, action.data],
			};
		case DELETE_NOTIFICATION_SUCCESS:
			return {
				...state,
				total: state.total - 1,
			};
		case LOADING_NOTIFICATION_GROUP_SUCCESS:
			return {
				...state,
				groups: action.data,
				isLoading: false,
			};
		case LOADING_NOTIFICATION_GROUP_FAIL:
			return {
				...state,
				groups: [],
				isLoading: false,
			};
		case CREATE_NOTIFICATION_GROUP_SUCCESS:
			return {
				...state,
				groups: [...state.groups, action.data],
			};
		case DELETE_NOTIFICATION_GROUP_SUCCESS: {
			const newGroup = [...state.groups].filter((groups) => {
				const { _id } = groups;
				return _id !== action.id;
			});
			return {
				...state,
				groups: newGroup,
			};
		}
		default:
			return state;
	}
}
