export const VIEW_COMPLAIN = "VIEW_COMPLAIN";

export const LOADING_COMPLAIN = "LOADING_COMPLAIN";
export const LOADING_COMPLAIN_SUCCESS = "LOADING_COMPLAIN_SUCCESS";
export const LOADING_COMPLAIN_FAIL = "LOADING_COMPLAIN_FAIL";

export const DELETE_COMPLAIN = "DELETE_COMPLAIN";
export const DELETE_COMPLAIN_SUCCESS = "DELETE_COMPLAIN_SUCCESS";
export const DELETE_COMPLAIN_FAIL = "DELETE_COMPLAIN_FAIL";

export const DELETE_COMPLAIN_BATCH = "DELETE_COMPLAIN_BATCH";
