import {
	LOADING_CATEGORY, LOADING_CATEGORY_SUCCESS, LOADING_CATEGORY_FAIL,
	CREATE_CATEGORY, CREATE_CATEGORY_SUCCESS, EDIT_CATEGORY,
} from "@/const";
import { call, put, takeLatest } from "redux-saga/effects";
import {
	getAllCategories, createCategory, updateCategory,
} from "@/api/category";
import { notification } from "antd";
import { history } from "@/configureStore";

function* fetchCategories({ payload }) {
	try {
		const categories = yield call(getAllCategories, payload);
		if (categories) {
			const { code, data } = categories;
			if (code === 200) {
				yield put({
					type: LOADING_CATEGORY_SUCCESS,
					payload: {
						data,
						page: payload.page,
						perPage: payload.limit,
						total: data?.length,
					},
				});
			} else {
				yield put({
					type: LOADING_CATEGORY_FAIL,
					payload: {
						page: payload.page,
						perPage: payload.limit,
						total: 0,
					},
				});
			}
		}
	} catch (error) {
		console.log(error);
	}
}

function* addCategory({ payload }) {
	try {
		const createCate = yield call(createCategory, payload);
		const { code, data } = createCate;
		if (code === 200) {
			yield put({
				type: CREATE_CATEGORY_SUCCESS,
				data,
			});
			yield notification.success({ message: "Thêm danh mục thành công" });
			yield history.push("/categories");
		} else {
			yield notification.error({ message: "Thêm danh mục thất bại" });
		}
	} catch (error) {
		console.log(error);
	}
}

function* editCategory({ payload, id }) {
	try {
		const category = yield call(updateCategory, id, payload);
		const { code, data } = category;
		if (code === 200) {
			yield put({
				type: LOADING_CATEGORY,
				data,
			});
			yield notification.success({ message: "Sửa danh mục thành công" });
			yield history.push("/categories");
		} else {
			yield notification.error({ message: "Sửa danh mục thất bại" });
		}
	} catch (error) {
		console.log(error);
	}
}

export default function* categorySaga() {
	yield takeLatest(LOADING_CATEGORY, fetchCategories);
	yield takeLatest(CREATE_CATEGORY, addCategory);
	yield takeLatest(EDIT_CATEGORY, editCategory);
}
