import API from "@/libs/api";
import { queryString } from "@/libs/common";

const basePath = "/category";

export const createCategory = (data) => API.post(`${basePath}/create`, data);

export const updateCategory = (id, data) => API.put(`${basePath}/update/${id}`, data);

export const deleteCategory = (id) => API.delete(`${basePath}/delete/${id}`);

export const getAllCategories = (params) => API.get(`${basePath}/all?${queryString(params)}`);

export const getDetailCategory = (id) => API.get(`${basePath}/details/${id}`);
