import {
	LOADING_SERVICE, LOADING_SERVICE_SUCCESS, LOADING_SERVICE_FAIL,
	DELETE_SERVICE, DELETE_SERVICE_SUCCESS,
} from "@/const";

const initialState = {
	services: [],
	error: "",
	page: 1,
	perPage: 20,
	total: 0,
	isLoading: false,
};

export default function ServiceReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_SERVICE:
			return {
				...state,
				isLoading: true,
			};
		case DELETE_SERVICE:
		case DELETE_SERVICE_SUCCESS:
			return {
				...state,
			};
		case LOADING_SERVICE_SUCCESS: {
			const {
				data, page, perPage, total,
			} = action.payload;
			return {
				...state,
				services: data?.data,
				page: Number(page),
				perPage: Number(perPage),
				total,
				isLoading: false,
			};
		}
		case LOADING_SERVICE_FAIL:
			return {
				...state,
				services: [],
				isLoading: false,
			};
		default:
			return state;
	}
}
