import {
	LOADING_WITHDRAWAL, LOADING_WITHDRAWAL_SUCCESS, LOADING_WITHDRAWAL_FAIL,
	DELETE_WITHDRAWAL, DELETE_WITHDRAWAL_SUCCESS,
} from "@/const";

const initialState = {
	withdrawals: [],
	page: 1,
	perPage: 10,
	total: 0,
	isLoading: false,
};

export default function WithdrawalReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_WITHDRAWAL:
			return {
				...state,
				isLoading: true,
			};
		case DELETE_WITHDRAWAL:
			return {
				...state,
			};
		case LOADING_WITHDRAWAL_SUCCESS: {
			const {
				data, page, perPage, total,
			} = action.payload;
			return {
				...state,
				withdrawals: data,
				page: Number(page),
				perPage: Number(perPage),
				total,
				isLoading: false,
			};
		}
		case LOADING_WITHDRAWAL_FAIL: {
			return {
				...state,
				page: Number(action.payload?.page) || 1,
				perPage: Number(action.payload?.perPage) || 10,
				total: action.payload?.total || 0,
				withdrawals: [],
				isLoading: false,
			};
		}
		case DELETE_WITHDRAWAL_SUCCESS:
			return {
				...state,
				total: state.total - 1,
			};
		default:
			return state;
	}
}
