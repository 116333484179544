import {
	LOADING_CUSTOMER, LOADING_CUSTOMER_FAIL, LOADING_CUSTOMER_SUCCESS,
	LOADING_PARTNER, LOADING_PARTNER_SUCCESS, LOADING_PARTNER_FAIL,
	LOADING_ACCOUNT_SUCCESS, LOADING_ACCOUNT_FAIL, LOADING_ACCOUNT,
	CREATE_ACCOUNT, CHANGE_STATUS_ACCOUNT, CHANGE_PASSWORD, SEARCH_CUSTOMER,
	SEARCH_PARTNER, EDIT_USER, DELETE_USER, EDIT_PARTNER,
} from "@/const";
import { call, put, takeLatest } from "redux-saga/effects";
import {
	createAdmin, createManager, createSupporter,
	listAccount, listCustomer, listPartner,
	changeStatusAccount, changePassword, searchPartner, searchCustomer,
	editAdminUser, deleteAdminUser, editPartnerUser,
} from "@/api/account";
import { notification } from "antd";
import { history } from "@/configureStore";

function* fetchCustomer({ payload }) {
	try {
		const customers = yield call(listCustomer, payload);
		if (customers) {
			const { code, data, msg } = customers;
			if (code === 200) {
				yield put({
					type: LOADING_CUSTOMER_SUCCESS,
					payload: {
						data: data?.data,
						page: payload.page,
						perPage: payload.limit,
						total: data?.pageInfo?.total,
					},
				});
			} else {
				yield put({
					type: LOADING_CUSTOMER_FAIL,
					payload: {
						page: payload.page,
						perPage: payload.limit,
						total: 0,
					},
				});
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: LOADING_CUSTOMER_FAIL,
			payload: {
				page: payload.page,
				perPage: payload.limit,
				total: 0,
			},
		});
	}
}

function* fetchPartner({ payload }) {
	try {
		const customers = yield call(listPartner, payload);
		if (customers) {
			const { code, data, msg } = customers;
			if (code === 200) {
				yield put({
					type: LOADING_PARTNER_SUCCESS,
					payload: {
						data: data?.data,
						page: payload.page,
						perPage: payload.limit,
						total: data?.pageInfo?.total,
					},
				});
			} else {
				yield put({
					type: LOADING_PARTNER_FAIL,
					payload: {
						page: payload.page,
						perPage: payload.limit,
						total: 0,
					},
				});
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: LOADING_PARTNER_FAIL,
			payload: {
				page: payload.page,
				perPage: payload.limit,
				total: 0,
			},
		});
	}
}

function* fetchAccount() {
	try {
		const accounts = yield call(listAccount, {});
		if (accounts) {
			const { code, data, msg } = accounts;
			if (code === 200) {
				yield put({
					type: LOADING_ACCOUNT_SUCCESS,
					payload: {
						data,
					},
				});
			} else {
				yield put({ type: LOADING_ACCOUNT_FAIL });
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		console.log(error);
		yield put({ type: LOADING_ACCOUNT_FAIL });
	}
}

function* createAccount({ payload }) {
	try {
		// const { roles, body } = payload;
		// console.log("🚀 ~ file: user.js ~ line 131 ~ function*createAccount ~ payload", payload);

		// const getRolesAccount = () => {
		// 	switch (roles) {
		// 		case "A":
		// 			return createAdmin;
		// 		case "M":
		// 			return createManager;
		// 		case "S":
		// 			return createSupporter;
		// 		default:
		// 			return null;
		// 	}
		// };

		const create = yield call(createAdmin, payload);
		if (create) {
			const { code, msg } = create;
			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else {
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Thất bại",
			description: "Tạo tài khoản thất bại, thử lại sau !",
		});
	}
}

function* editAdminAccount({ payload }) {
	try {
		const { id, data } = payload;
		const edit = yield call(editAdminUser, id, data);
		console.log("edit>>", edit);
		if (edit) {
			const { code, msg } = edit;
			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else {
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Thất bại",
			description: "Tạo tài khoản thất bại, thử lại sau !",
		});
	}
}

function* editPartnerAccount({ payload, callback }) {
	try {
		const { id, data } = payload;
		const edit = yield call(editPartnerUser, id, data);
		if (edit) {
			const { code, msg, data: resData } = edit;
			if (code === 200) {
				yield notification.success({
					description: "Sửa thông tin thành công",
					message: "Thành công",
				});
				if (callback) {
					callback(resData);
				}
			} else {
				yield notification.error({
					message: "Thất bại",
					description: "Sửa thông tin thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Thất bại",
			description: "Tạo tài khoản thất bại, thử lại sau !",
		});
	}
}

function* deleteAdminAccount({ payload }) {
	try {
		const delUser = yield call(deleteAdminUser, payload);
		if (delUser) {
			const { code, msg } = delUser;
			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else {
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Thất bại",
			description: "Tạo tài khoản thất bại, thử lại sau !",
		});
	}
}

function* changeStatus({ payload }) {
	try {
		const change = yield call(changeStatusAccount, payload);

		if (change) {
			const { code, msg } = change;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
			} else {
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Thất bại",
			description: "Đổi loại tài khoản thất bại, thử lại sau !",
		});
	}
}

function* changePwd({ payload }) {
	try {
		const { body, onSuccess } = payload;

		const change = yield call(changePassword, body);

		if (change) {
			const { code, msg } = change;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
				yield onSuccess();
			} else {
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		yield notification.error({
			message: "Thất bại",
			description: "Đổi loại tài khoản thất bại, thử lại sau !",
		});
	}
}

function* searchCustomers({ payload }) {
	try {
		const customers = yield call(searchCustomer, payload);
		if (customers) {
			const { code, data, msg } = customers;
			if (code === 200) {
				yield put({
					type: LOADING_CUSTOMER_SUCCESS,
					payload: {
						data: data?.[0]?.data,
						page: payload.page,
						perPage: payload.limit,
						total: data?.[0]?.pageInfo?.[0]?.total,
					},
				});
			} else {
				yield put({
					type: LOADING_CUSTOMER_FAIL,
					payload: {
						page: payload.page,
						perPage: payload.limit,
						total: 0,
					},
				});
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: LOADING_CUSTOMER_FAIL,
			payload: {
				page: payload.page,
				perPage: payload.limit,
				total: 0,
			},
		});
	}
}

function* searchPartners({ payload }) {
	try {
		const body = payload?.workArea;
		const params = { ...payload };
		delete params.workArea;
		const partners = yield call(searchPartner, params, body);
		if (partners) {
			const { code, data, msg } = partners;
			if (code === 200) {
				yield put({
					type: LOADING_PARTNER_SUCCESS,
					payload: {
						data: data?.[0]?.data,
						page: payload.page,
						perPage: payload.limit,
						total: data?.[0]?.pageInfo?.[0]?.total,
					},
				});
			} else {
				yield put({
					type: LOADING_PARTNER_FAIL,
					payload: {
						page: payload.page,
						perPage: payload.limit,
						total: 0,
					},
				});
				yield notification.error({
					message: "Thất bại",
					description: msg,
				});
			}
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: LOADING_PARTNER_FAIL,
			payload: {
				page: payload.page,
				perPage: payload.limit,
				total: 0,
			},
		});
	}
}

export default function* userSaga() {
	yield takeLatest(LOADING_CUSTOMER, fetchCustomer);
	yield takeLatest(LOADING_PARTNER, fetchPartner);
	yield takeLatest(LOADING_ACCOUNT, fetchAccount);
	yield takeLatest(CREATE_ACCOUNT, createAccount);
	yield takeLatest(CHANGE_STATUS_ACCOUNT, changeStatus);
	yield takeLatest(CHANGE_PASSWORD, changePwd);
	yield takeLatest(SEARCH_CUSTOMER, searchCustomers);
	yield takeLatest(SEARCH_PARTNER, searchPartners);
	yield takeLatest(EDIT_USER, editAdminAccount);
	yield takeLatest(EDIT_PARTNER, editPartnerAccount);
	yield takeLatest(DELETE_USER, deleteAdminAccount);
}
