import React from "react";
import { Result, Button } from "antd";
import { history } from "@/configureStore";

const AccessDeny = () => {
	const goBack = () => history.goBack();

	return (
		<Result
			status="403"
			title="403"
			subTitle="Xin lỗi, bạn không có quyền truy cập trang này"
			extra={(
				<Button
					type="primary"
					onClick={goBack}
					size="large"
				>
					Quay lại
				</Button>
			)}
		/>
	);
};

export default AccessDeny;
