import {
	LOADING_JGC, LOADING_JO, LOADING_JP, LOADING_JS,
	LOADING_JOB_AUTO_SUCCESS, LOADING_JOB_AUTO_FAIL,
} from "@/const";

const initialState = {
	automatics: [],
	isLoading: false,
	total: 0,
	page: 1,
	perPage: 10,
};

export default function AutomaticServiceReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_JGC:
		case LOADING_JO:
		case LOADING_JP:
		case LOADING_JS:
			return {
				...state,
				isLoading: true,
			};
		case LOADING_JOB_AUTO_SUCCESS: {
			const {
				total, data, perPage, page,
			} = action.payload;

			return {
				...state,
				automatics: data,
				total,
				perPage,
				page,
				isLoading: false,
			};
		}

		case LOADING_JOB_AUTO_FAIL:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
