import {
	LOADING_EDUCATE, LOADING_EDUCATE_SUCCESS, LOADING_EDUCATE_FAIL,
	LOADING_VIDEO_CATEGORY, LOADING_VIDEO_CATEGORY_SUCCESS, LOADING_VIDEO_CATEGORY_FAIL,
	CREATE_VIDEO_CATEGORY,
	CREATE_VIDEO_CATEGORY_SUCCESS,
	CREATE_VIDEO_CATEGORY_FAIL,
	DELETE_VIDEO_CATEGORY,
} from "@/const";

const initialState = {
	educates: [],
	videoCategories: [],
	isLoading: false,
};

export default function EducateReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_EDUCATE:
			return {
				...state,
				isLoading: true,
			};
		case LOADING_EDUCATE_SUCCESS: {
			return {
				...state,
				educates: action.data,
				isLoading: false,
			};
		}
		case LOADING_EDUCATE_FAIL: {
			return {
				...state,
				educates: [],
				isLoading: false,
			};
		}
		case LOADING_VIDEO_CATEGORY:
			return {
				...state,
				isLoading: true,
			};
		case LOADING_VIDEO_CATEGORY_SUCCESS:
			return {
				...state,
				videoCategories: action.data,
				isLoading: false,
			};
		case LOADING_VIDEO_CATEGORY_FAIL:
			return {
				...state,
				videoCategories: [],
				isLoading: false,
			};
		case CREATE_VIDEO_CATEGORY:
		case CREATE_VIDEO_CATEGORY_FAIL:
			return {
				...state,
			};
		case CREATE_VIDEO_CATEGORY_SUCCESS:
			return {
				...state,
				videoCategories: [action?.data[0], ...state?.videoCategories],
			};
		case DELETE_VIDEO_CATEGORY:
			return {
				...state,
				videoCategories: [...state.videoCategories]
					.filter((category) => category?._id !== action?.payload),
			};
		default:
			return state;
	}
}
