import {
	createVideo, deleteVideo, getVideo, editVideo,
	getVideoCategory, createVideoCategory, deleteVideoCategory, updateVideoCategory,
} from "@/api/introduction";
import { history } from "@/configureStore";
import {
	LOADING_EDUCATE, LOADING_EDUCATE_SUCCESS, LOADING_EDUCATE_FAIL,
	CREATE_EDUCATE, DELETE_EDUCATE, EDIT_EDUCATE, LOADING_VIDEO_CATEGORY,
	LOADING_VIDEO_CATEGORY_SUCCESS, LOADING_VIDEO_CATEGORY_FAIL, CREATE_VIDEO_CATEGORY,
	DELETE_VIDEO_CATEGORY,
	EDIT_VIDEO_CATEGORY,
	CREATE_VIDEO_CATEGORY_SUCCESS,
} from "@/const";
import { notification } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";

function* fetchEducates() {
	try {
		const educates = yield call(getVideo, {});

		if (educates) {
			const { code, data } = educates;
			if (code === 200) {
				yield put({ type: LOADING_EDUCATE_SUCCESS, data });
			} else {
				yield put({ type: LOADING_EDUCATE_FAIL });
			}
		}
	} catch (error) {
		console.log(error);
	}
}

function* fetchCategoryVideo() {
	try {
		const categories = yield call(getVideoCategory, {});
		if (categories) {
			const { code, data } = categories;
			if (code === 200) {
				yield put({ type: LOADING_VIDEO_CATEGORY_SUCCESS, data });
			} else {
				yield put({ type: LOADING_VIDEO_CATEGORY_FAIL });
			}
		}
	} catch (error) {
		console.log(error);
	}
}

function* createCateVideo({ payload }) {
	try {
		const create = yield call(createVideoCategory, payload);
		if (create) {
			const { code, msg, data } = create;
			if (code === 200) {
				yield put({ type: CREATE_VIDEO_CATEGORY_SUCCESS, data });
				yield notification.success({
					description: msg,
					message: "Thêm danh mục thành công",
				});
			} else {
				yield notification.error({
					description: msg,
					message: "Thêm danh mục thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Tạo danh mục đào tạo thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* deleteCateVideo({ payload }) {
	try {
		const del = yield call(deleteVideoCategory, payload);
		if (del) {
			const { code, msg } = del;
			if (code === 200) {
				yield notification.success({
					message: "Đã xóa danh mục",
					description: msg,
				});
			} else {
				yield notification.error({
					description: msg,
					message: "Xóa danh mục thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Xóa chương trình đào tạo thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* editCateVideo({ payload }) {
	try {
		const edit = yield call(updateVideoCategory, payload.data, payload.id);
		if (edit) {
			const { code, msg } = edit;
			if (code === 200) {
				yield put({ type: LOADING_VIDEO_CATEGORY });
				yield notification.success({
					description: msg,
					message: "Sửa danh mục thành công",
				});
			} else {
				yield notification.error({
					description: msg,
					message: "Sửa danh mục thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Sửa chương trình đào tạo thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* createEducate({ payload }) {
	try {
		const create = yield call(createVideo, payload);

		if (create) {
			const { code, msg } = create;

			console.log("res>>", create);
			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Tạo chương trình đào tạo thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* editEducate({ payload }) {
	try {
		const { id, body } = payload;
		const edit = yield call(editVideo, id, body);

		if (edit) {
			const { code, msg } = edit;

			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Sửa chương trình đào tạo thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* deleteEducate({ payload }) {
	try {
		const del = yield call(deleteVideo, payload);

		if (del) {
			const { code, msg } = del;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Xóa chương trình đào tạo thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

export default function* educateSaga() {
	yield takeLatest(LOADING_EDUCATE, fetchEducates);
	yield takeLatest(CREATE_EDUCATE, createEducate);
	yield takeLatest(EDIT_EDUCATE, editEducate);
	yield takeLatest(DELETE_EDUCATE, deleteEducate);
	yield takeLatest(LOADING_VIDEO_CATEGORY, fetchCategoryVideo);
	yield takeLatest(CREATE_VIDEO_CATEGORY, createCateVideo);
	yield takeLatest(DELETE_VIDEO_CATEGORY, deleteCateVideo);
	yield takeLatest(EDIT_VIDEO_CATEGORY, editCateVideo);
}
