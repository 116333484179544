import API from "@/libs/api";
import { queryString } from "@/libs/common";

const basePath = "/notification";

export const createNotification = (data) => API.post(`${basePath}/create-notification-template`, data);

export const getNotificationTemplate = (params) => API.get(`${basePath}/get-notification-template?${queryString(params)}`);

export const detailNotificationTemplate = (id) => API.get(`${basePath}/get-notification-template/${id}`);

export const updateNotificationTemplate = (id, body) => API.put(`${basePath}/update-notification-template/${id}`, body);

export const deleteNotificationTemplate = (id) => API.delete(`${basePath}/delete-notification-template/${id}`);

export const pushNotification = (id) => API.post(`${basePath}/push-notification-by-template/${id}`);
