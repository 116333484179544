import { all } from "redux-saga/effects";
import authSaga from "./auth";
import couponSaga from "./coupon";
import userSaga from "./user";
import categorySaga from "./category";
import faqSaga from "./faq";
import educateSaga from "./educate";
import serviceSaga from "./services";
import automaticSaga from "./automatic";
import complainSaga from "./complain";
import notificationSaga from "./notification";
import roleSaga from "./role";

export default function* rootSaga() {
	yield all([
		authSaga(),
		userSaga(),
		couponSaga(),
		categorySaga(),
		faqSaga(),
		educateSaga(),
		serviceSaga(),
		automaticSaga(),
		complainSaga(),
		notificationSaga(),
		roleSaga(),
	]);
}
