import { refreshTokenAction } from "@/actions/auth";
import store from "@/configureStore";
import { ENDPOINT, TIMEOUT } from "@/const";
import axios from "axios";
import nProgress from "nprogress";

nProgress.configure({
	minimum: 0.8,
	easing: "ease",
	speed: 500,
	showSpinner: false,
});

const API = axios.create({
	baseURL: ENDPOINT,
	timeout: TIMEOUT,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});

// for request
API.interceptors.request.use(
	async (config) => {
		nProgress.start();
		const token = await store.getState().auth?.token;

		if (token) {
			// eslint-disable-next-line no-param-reassign
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	(error) => {
		nProgress.done();
		return Promise.reject(error);
	},
);

// for response
API.interceptors.response.use(
	(response) => {
		nProgress.done();
		return response.data;
	},
	(error) => {
		nProgress.done();
		if (error.response.status === 401) {
			// handle refresh token
			const {
				auth: {
					refreshToken,
					profile: { uuid },
				},
			} = store.getState();

			const payload = {
				body: { refreshToken, uuid },
				onSuccess: () => window.location.reload(),
			};

			return store.dispatch(refreshTokenAction(payload));
		}

		return Promise.reject(error);
	},
);

export default API;
