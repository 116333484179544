export const VIEW_INTRODUCTION = "VIEW_INTRODUCTION";
export const ADD_INTRODUCTION = "ADD_INTRODUCTION";

export const CREATE_INTRODUCTION = "CREATE_INTRODUCTION";
export const CREATE_INTRODUCTION_SUCCESS = "CREATE_INTRODUCTION_SUCCESS";
export const CREATE_INTRODUCTION_FAIL = "CREATE_INTRODUCTION_FAIL";

export const LOADING_INTRODUCTION = "LOADING_INTRODUCTION";
export const LOADING_INTRODUCTION_SUCCESS = "LOADING_INTRODUCTION_SUCCESS";
export const LOADING_INTRODUCTION_FAIL = "LOADING_INTRODUCTION_FAIL";

export const EDIT_INTRODUCTION = "EDIT_INTRODUCTION";
export const EDIT_INTRODUCTION_SUCCESS = "EDIT_INTRODUCTION_SUCCESS";
export const EDIT_INTRODUCTION_FAIL = "EDIT_INTRODUCTION_FAIL";

export const DELETE_INTRODUCTION = "DELETE_INTRODUCTION";
export const DELETE_INTRODUCTION_SUCCESS = "DELETE_INTRODUCTION_SUCCESS";
export const DELETE_INTRODUCTION_FAIL = "DELETE_INTRODUCTION_FAIL";

export const DELETE_INTRODUCTION_BATCH = "DELETE_INTRODUCTION_BATCH";
