import API from "@/libs/api";
import { queryString } from "@/libs/common";

const basePath = "/role";

export const listRole = () => API.get(`${basePath}/list-role`);

export const createRole = (data) => API.post(`${basePath}/create-role`, data);

export const updateRole = (id, data) => API.put(`${basePath}/update-role/${id}`, data);

export const deleteRole = (id) => API.delete(`${basePath}/delete-role/${id}`);

export const detailRole = (id) => API.get(`${basePath}/detail-role/${id}`);
