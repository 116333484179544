import {
	createQa, deleteQa, getQa, editQa,
} from "@/api/introduction";
import { history } from "@/configureStore";
import {
	LOADING_FAQ, LOADING_FAQ_SUCCESS, LOADING_FAQ_FAIL,
	CREATE_FAQ, DELETE_FAQ, EDIT_FAQ,
} from "@/const";
import { notification } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";

function* fetchFaqs() {
	try {
		const faqs = yield call(getQa, {});

		if (faqs) {
			const { code, data } = faqs;
			if (code === 200) {
				yield put({ type: LOADING_FAQ_SUCCESS, data });
			} else {
				yield put({ type: LOADING_FAQ_FAIL });
			}
		}
	} catch (error) {
		console.log(error);
	}
}

function* createFaq({ payload }) {
	try {
		const create = yield call(createQa, payload);

		if (create) {
			const { code, msg } = create;

			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Tạo câu hỏi thường gặp thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* editFaq({ payload }) {
	try {
		const { id, body } = payload;
		const edit = yield call(editQa, id, body);

		if (edit) {
			const { code, msg } = edit;

			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Sửa câu hỏi thường gặp thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* deleteFaq({ payload }) {
	try {
		const del = yield call(deleteQa, payload);
		if (del) {
			const { code, msg } = del;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Xóa câu hỏi thường gặp thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

export default function* faqSaga() {
	yield takeLatest(LOADING_FAQ, fetchFaqs);
	yield takeLatest(CREATE_FAQ, createFaq);
	yield takeLatest(EDIT_FAQ, editFaq);
	yield takeLatest(DELETE_FAQ, deleteFaq);
}
