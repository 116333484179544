import API from "@/libs/api";
import { queryString } from "@/libs/common";

const basePath = "/job";

export const getListJob = (params) => API.get(`${basePath}/list-job?${queryString(params)}`);

export const searchListJob = (data) => API.post(`${basePath}/search`, data);

export const createJob = (data) => API.post(`${basePath}/create-job`, data);

export const getDetailJob = (id) => API.get(`${basePath}/${id}`);

export const getPartnerCanAssign = (id) => API.get(`${basePath}/partners-can-assign/${id}`);

export const updateAssignTypeJob = (data) => API.post(`${basePath}/change-assign-type`, data);

export const cancelJob = (id) => API.delete(`${basePath}/delete/${id}`);

export const assignJob = (data) => API.post(`${basePath}/assign-job`, data);

export const createBillSuddenJob = (data) => API.post("job-sudden/create-bill-sudden-job/", data);

export const confirmPaymentSuddenJob = (data) => API.post("job-sudden/confirm-payment-sudden-job/", data);

export const createBillPeriodicJob = (data) => API.post("job-periodic/create-bill-periodic-job/", data);

export const confirmPaymentPeriodicJob = (data) => API.post("job-periodic/confirm-payment-periodic-job/", data);

export const createGeneralCleaningJob = (data) => API.post("job-general-cleaning/create", data);

export const createOtherJob = (data) => API.post("job-other/create", data);

export const createBillJob = (data) => API.post(`${basePath}/create-bill-job`, data);

export const confirmPaymentJob = (data) => API.post(`${basePath}/confirm-payment-job`, data);

export const changedOtherPartnerToJoin = (id, data) => API.patch(`${basePath}/changed-other-partner-to-join/${id}`, data);
