import {
	LOADING_ROLE, LOADING_ROLE_SUCCESS, LOADING_ROLE_FAIL,
	CREATE_ROLE, DELETE_ROLE, EDIT_ROLE, SEND_ROLE,
} from "@/const";
import { call, put, takeLatest } from "redux-saga/effects";
import {
	listRole, updateRole, createRole,
} from "@/api/role";
import { notification } from "antd";
import { history } from "@/configureStore";

function* fetchRoles({ payload }) {
	try {
		const roles = yield call(listRole, payload);
		if (roles) {
			const { code, data } = roles;
			if (code === 200) {
				yield put({ type: LOADING_ROLE_SUCCESS, data });
			} else {
				yield put({ type: LOADING_ROLE_FAIL });
			}
		}
	} catch (error) {
		console.log(error);
	}
}

function* editRole({ payload }) {
	try {
		const { id, body } = payload;
		const edit = yield call(updateRole, id, body);

		if (edit) {
			const { code, msg } = edit;

			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Sửa thành công",
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Sửa thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Sửa vai trò thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* addRole({ payload }) {
	try {
		const create = yield call(createRole, payload);

		if (create) {
			const { code, msg } = create;

			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Tạo khuyến mãi thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

export default function* roleSaga() {
	yield takeLatest(LOADING_ROLE, fetchRoles);
	yield takeLatest(EDIT_ROLE, editRole);
	yield takeLatest(CREATE_ROLE, addRole);
}
