/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import {
	PUBLISH,
	PENDING,
	ALL,
	DELETE,
	PAID,
	SUCCESS,
	ERROR,
	FAILED,
	ADMINISTRATOR,
	DEFAULT,
	CREATE_AT,
	DESC,
	BASE_URL,
	CLEANED,
	MOVED,
	VIEW_ADMIN,
	VIEW_PARTNER,
	VIEW_CUSTOMER,
	VIEW_SERVICE,
	VIEW_SERVICE_SETTING,
	VIEW_VOUCHER,
	VIEW_REFERAL,
	VIEW_NOTIFICATION,
	VIEW_SETTING,
	VIEW_ROLE,
	GENERAL,
	SET_CHARGE,
	SET_PARTNER,
	SET_STATUS_PARTNER,
} from "@/const";
import moment from "moment-timezone";
import { Badge, Tag } from "antd";
import {
	DeleteOutlined,
	UserOutlined,
	BellOutlined,
	CheckCircleFilled,
	SettingOutlined,
	InsertRowAboveOutlined,
	BarcodeOutlined,
	QuestionCircleOutlined,
	SmileOutlined,
} from "@ant-design/icons";
import upperFirst from "lodash/upperFirst";
import store from "@/configureStore";

export const CODE_PACK_SUDDEN_PRICE = "SVP00997";

export const autoSizeTextArea = {
	maxRows: 5,
	minRows: 3,
};

export const labelStyle = {
	labelCol: { span: 4 },
	labelAlign: "left",
};
export const labelStyleSmall = {
	labelCol: { span: 6 },
	labelAlign: "left",
};

export const labelChildren = {
	...labelStyle,
	labelAlign: "right",
};

export const genders = [
	{ value: "M", label: "Nam" },
	{ value: "F", label: "Nữ" },
	{ value: "O", label: "Khác" },
];

export const typeImageJob = [
	{ value: "SJob", key: "suddenJob" },
	{ value: "PJob", key: "periodicJob" },
	{ value: "CJob", key: "otherJob" },
	{ value: "OJob", key: "generalCleaningJob" },
];

export const typePopupJob = [
	{ value: "POPUP_S", key: "suddenJob" },
	{ value: "POPUP_P", key: "periodicJob" },
	{ value: "POPUP_C", key: "generalCleaningJob" },
	{ value: "POPUP_O", key: "otherJob" },
];

export const TrashCompleteStatus = [
	{ key: CLEANED, value: CLEANED, status: "processing" },
	{ key: MOVED, value: MOVED, status: "error" },
];

export const status = [
	{
		key: 1,
		value: SUCCESS,
		status: "success",
		label: "Hoàn thành",
	},
	{
		key: 2,
		value: PENDING,
		status: "processing",
		label: "Đang chờ",
	},
	{
		key: 3,
		value: FAILED,
		status: "error",
		label: "Thất bại",
	},
];

export const roles = [
	// { label: "Quản trị viên cấp cao", value: "SA" },
	{ label: "Quản trị viên", value: "A" },
	{ label: "Quản lý", value: "M" },
	{ label: "Hỗ trợ", value: "S" },
];

export const statusPayment = [
	{ key: 0, value: "W", label: "Chưa thanh toán" },
	{ key: 1, value: "S", label: "Đã thanh toán" },
	{ key: 2, value: "F", label: "Thất bại" },
];

export const statusCoupon = [
	{ key: 0, value: "all", label: "Tất cả" },
	{ key: 1, value: "U", label: "Không giới hạn người nhận" },
	{ key: 2, value: "L", label: "Giới hạn người nhận" },
];

export const typeJobCoupon = [
	{ key: 0, value: "all", label: "Tất cả" },
	{ key: 1, value: "S", label: "Ca lẻ" },
	{ key: 2, value: "P", label: "Ca Định kỳ" },
];

export const statusRef = [
	{ key: 1, value: "RP", label: "Nhân viên" },
	{ key: 2, value: "RC", label: "Khách hàng" },
];

export const statusFaq = [
	{ value: "Các câu hỏi thường gặp", label: "Các câu hỏi thường gặp" },
	{ value: "Các tình huống hỗ trợ", label: "Các tình huống hỗ trợ" },
	{ value: "Nhóm câu hỏi mới", label: "Nhóm câu hỏi mới" },
];

export const statusComplain = [
	{ value: "C", label: "Góp ý" },
	{ value: "F", label: "Khiếu nại" },
];

export const statusEducate = [
	{
		value: "Tổng quan về ứng dụng giúp việc",
		label: "Tổng quan về Agiupviec",
	},
	{
		value: "Hướng dẫn sử dụng ứng dụng",
		label: "Hướng dẫn sử dụng ứng dụng",
	},
	{ value: "Kỹ năng làm việc", label: "Kỹ năng làm việc" },
	{ value: "Hướng dẫn kĩ năng thực hành", label: "Kỹ năng thực hành" },
	{
		value: "Hướng dẫn quy trình dọn dẹp",
		label: "Hướng dẫn quy trình dọn dẹp",
	},
];

export const statusWithdrawal = [
	{ value: "S", label: "Nạp tiền" },
	{ value: "G", label: "Rút tiền" },
];

export const statusOrder = [
	{ key: 2, value: PENDING, status: "processing" },
	{ key: 3, value: SUCCESS, status: "success" },
	{ key: 4, value: FAILED, status: "error" },
];

export const typeJobs = [
	{ value: "", label: "Tất cả" },
	{ value: "C", label: "Tổng vệ sinh" },
	{ value: "S", label: "Ca lẻ" },
	{ value: "P", label: "Định kỳ" },
	{ value: "O", label: "Khác" },
];

export const typeJobsFilter = [
	{ value: "C", text: "Tổng vệ sinh" },
	{ value: "S", text: "Ca lẻ" },
	{ value: "P", text: "Định kỳ" },
	{ value: "O", text: "Khác" },
];

export const statusJobs = [
	{ value: "", label: "Tất cả" },
	{ value: "WV", label: "Đang chờ xác thực" },
	{ value: "WS", label: "Đang chờ khảo sát" },
	{ value: "WP", label: "Đang chờ nhân viên" },
	{ value: "WPC", label: "Đang chờ nhân viên xác nhận" },
	{ value: "WST", label: "Đang chờ bắt đầu" },
	{ value: "P", label: "Đang làm" },
	{ value: "S", label: "Đã hoàn thành" },
	{ value: "F", label: "Thất bại" },
	{ value: "C", label: "Đã Hủy" },
];

export const statusUsersFilter = [
	{ value: "W", text: "Chưa xác nhận" },
	{ value: "P", text: "Đang chờ xác minh" },
	{ value: "C", text: "Đã xác nhận" },
	{ value: "B", text: "Khoá tài khoản" },
	{ value: "Q", text: "Nghỉ việc" },
	{ value: "L", text: "Tạm dừng" },
];

export const statusJobsFilter = [
	{ value: "WV", text: "Đang chờ xác thực" },
	{ value: "WS", text: "Đang chờ khảo sát" },
	{ value: "WP", text: "Đang chờ nhân viên" },
	{ value: "WPC", text: "Đang chờ nhân viên xác nhận" },
	{ value: "WST", text: "Đang chờ bắt đầu" },
	{ value: "P", text: "Đang làm" },
	{ value: "S", text: "Đã hoàn thành" },
	{ value: "F", text: "Thất bại" },
	{ value: "C", text: "Đã Hủy" },
];

export const statusUser = [
	{ key: 0, value: ALL, label: "Tất cả" },
	// { key: 3, value: "C", label: "Đã kích hoạt" },
	// { key: 4, value: "W", label: "Đang xác minh SĐT" },
	// { key: 4, value: "F", label: "Chưa kích hoạt" },
];

export const statusCategory = [
	{ key: 0, value: ALL, label: "Tất cả" },
	// { key: 1, value: "C", label: "Đã kích hoạt" },
	// { key: 2, value: "F", label: "Chưa kích hoạt" },
];

export const statusAutomaticService = [
	{ value: ALL, label: "Tất cả" },
	{ value: "JP", label: "Định kỳ" },
	{ value: "JS", label: "Ca lẻ" },
];

export const statusService = [
	{ value: ALL, label: "Tất cả" },
	{ value: "JGC", label: "Tổng vệ sinh" },
	{ value: "JO", label: "Khác" },
];

export const permissionsArray = [
	{
		label: "Quản trị",
		value: VIEW_ADMIN,
	},
	{
		label: "Nhân viên",
		value: VIEW_PARTNER,
	},
	{
		label: "Khách hàng",
		value: VIEW_CUSTOMER,
	},
	{
		label: "Dịch vụ",
		value: VIEW_SERVICE,
	},
	{
		label: "Báo giá dịch vụ",
		value: VIEW_SERVICE_SETTING,
	},
	{
		label: "Khuyến mãi",
		value: VIEW_VOUCHER,
	},
	{
		label: "Giới thiệu",
		value: VIEW_REFERAL,
	},
	{
		label: "Thông báo",
		value: VIEW_NOTIFICATION,
	},
	{
		label: "Hệ thống",
		value: VIEW_SETTING,
	},
	{
		label: "Phân quyền vai trò",
		value: VIEW_ROLE,
	},
	{
		label: "Điều chỉnh số dư",
		value: SET_CHARGE,
	},
	{
		label: "Sửa hồ sơ nhân viên",
		value: SET_PARTNER,
	},
	{
		label: "Thay đổi trạng thái nhân viên",
		value: SET_STATUS_PARTNER,
	},
];

export const statusPartner = [
	{ value: "P", label: "Đang chờ xác nhận" },
	{ value: "C", label: "Đã xác nhận" },
	{ value: "L", label: "Tạm dừng" },
	{ value: "B", label: "Khoá tài khoản" },
	{ value: "Q", label: "Nghỉ việc" },
];

export const statusAllService = [
	...statusService.slice(1, statusService.length),
	...statusAutomaticService.slice(1, statusAutomaticService.length),
];

export const actions = [
	{ value: DELETE, label: "Xóa", icon: <DeleteOutlined className="icon" /> },
];

export const formatCurrency = (value, unit) =>
	`${value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0} ${
		unit || "VNĐ"
	}`;

export const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export const getBase64FileList = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export const normFile = (e) => {
	if (Array.isArray(e)) {
		return e;
	}
	return e && e.fileList;
};

export const renderPublish = (record) => (
	<span>{moment(record).tz("Asia/Ho_Chi_Minh").format("DD-MM-YYYY")}</span>
);

export const formatTime = (dateTime, format) => {
	const time = moment(dateTime, "YYYY-MM-DDTHH:mm:ss.SSS").format(format);
	return time;
};

export const toCapitalized = (string) =>
	string.charAt(0).toUpperCase() + string.slice(1);

export const confirmInfoPartner = (record) => record?.status?.includes("C");

export const renderStatusJob = (record) => {
	let badgeStatus = "default";
	let text = "Không xác định";
	let stt = record;

	switch (record?.typeJob) {
		case "S":
			stt = record?.suddenJob?.status;
			break;
		case "P":
			stt = record?.periodicJob?.status;
			break;
		case "C":
			stt = record?.generalCleaningJob?.status;
			break;
		case "O":
			stt = record?.otherJob?.status;
			break;
		default:
			break;
	}

	switch (stt) {
		case "WV":
			badgeStatus = "default";
			text = "Đang xác thực";
			break;
		case "WS":
			badgeStatus = "default";
			text = "Đang chờ khảo sát";
			break;
		case "WP":
			badgeStatus = "warning";
			text = "Đang chờ nhân viên";
			break;
		case "WPC":
			badgeStatus = "orange";
			text = "Đang chờ nhân viên xác nhận";
			break;
		case "WST":
			badgeStatus = "processing";
			text = "Đang chờ bắt đầu";
			break;
		case "P":
			badgeStatus = "processing";
			text = "Đang làm";
			break;
		case "S":
			badgeStatus = "success";
			text = "Hoàn thành";
			break;
		case "C":
			badgeStatus = "error";
			text = "Đã hủy";
			break;
		case "F":
			badgeStatus = "error";
			text = "Thất bại";
			break;
		default:
			break;
	}

	return <Badge status={badgeStatus} text={text} />;
};

export const renderStatusSchedule = (record) => {
	let badgeStatus = "default";
	let text = "Không xác định";
	// console.log("STATUS", record);

	switch (record) {
		case "P":
			text = "Chưa diễn ra";
			badgeStatus = "default";
			break;
		case "PC":
			text = "đang làm";
			badgeStatus = "processing";
			break;
		case "C":
			text = "hoàn thành";
			badgeStatus = "success";
			break;
		case "CL":
			text = "xin phép huỷ";
			badgeStatus = "error";
			break;
		case "R":
			text = "bị admin huỷ";
			badgeStatus = "error";
			break;
		default:
			break;
	}

	return <Badge status={badgeStatus} text={text} />;
};

export const renderStatusPartner = (record) => {
	let badgeStatus = "default";
	let text = "Chưa xác nhận";
	// console.log("STATUS", record);

	switch (record) {
		case "W":
			text = "Chưa xác nhận";
			badgeStatus = "default";
			break;
		case "P":
			text = "Đang chờ xác nhận";
			badgeStatus = "processing";
			break;
		case "C":
			text = "Đã xác nhận";
			badgeStatus = "success";
			break;
		case "B":
			text = "Khoá tài khoản";
			badgeStatus = "error";
			break;
		case "Q":
			text = "Nghỉ việc";
			badgeStatus = "default";
			break;
		case "L":
			text = "Tạm dừng";
			badgeStatus = "processing";
			break;
		case "F":
			text = "Lỗi";
			badgeStatus = "error";
			break;
		default:
			break;
	}

	return <Badge status={badgeStatus} text={text} />;
};

export const renderTypeHouse = (record) => {
	let typeHouse = null;
	switch (record) {
		case "H":
			typeHouse = "Nhà phố";
			break;
		case "A":
			typeHouse = "Nhà chung cư";
			break;
		default:
			break;
	}
	return typeHouse;
};

export const renderShift = (record) => {
	let text = null;
	switch (record) {
		case "M":
			text = "Sáng";
			break;
		case "A":
			text = "Chiều";
			break;
		case "N":
			text = "Tối";
			break;
		default:
			break;
	}
	return text;
};

export const renderTypeJob = (record) => {
	let text = "Không xác định";
	switch (record) {
		case "S":
			text = "Ca lẻ";
			break;
		case "C":
			text = "Tổng vệ sinh";
			break;
		case "P":
			text = "Định kỳ";
			break;
		case "O":
			text = "Khác";
			break;
		default:
			break;
	}
	return <span>{text}</span>;
};

export const renderStatus = (record) => {
	let badgeStatus;
	const newValue = upperFirst(record?.toLowerCase());
	switch (newValue) {
		case PENDING:
			badgeStatus = "processing";
			break;
		case PAID:
		case SUCCESS:
		case PUBLISH:
			badgeStatus = "success";
			break;
		case ERROR:
		case FAILED:
			badgeStatus = "error";
			break;
		default:
			badgeStatus = "default";
			break;
	}
	return <Badge status={badgeStatus} text={record} />;
};

export const renderActive = (record) => {
	let badgeStatus = "default";
	let text = "Không xác định";
	switch (record) {
		case "W":
			badgeStatus = "processing";
			text = "Đang xác minh SĐT";
			break;
		case "P":
			badgeStatus = "warning";
			text = " Chờ xác minh ";
			break;
		case true:
		case "C":
			badgeStatus = "success";
			text = "Đã kích hoạt";
			break;
		case false:
		case "F":
			badgeStatus = "error";
			text = "Chưa kích hoạt";
			break;
		default:
			break;
	}
	return <Badge status={badgeStatus} text={text} />;
};

export const renderStatusPayment = (record) => {
	let text = "Không xác định";
	let tag = "default";
	switch (record) {
		case "W":
			text = "Chưa thanh toán";
			tag = "processing";
			break;
		case "S":
			text = "Đã thanh toán";
			tag = "success";
			break;
		case "F":
			text = "Thất bại";
			tag = "error";
			break;
		default:
			break;
	}
	return <Tag color={tag}>{text}</Tag>;
};

export const renderEndableAssign = (record) => {
	let text = "Tắt";
	let stt = "error";
	// console.log(record);
	if (record === true) {
		text = "Bật";
		stt = "success";
	}
	return <Badge status={stt} text={text} />;
};

export const renderTypeAccount = (type) => {
	switch (type) {
		case "SA":
			return "Quản trị viên cap cấp";
		case "A":
			return "Quản trị viên";
		case "M":
			return "Quản lý";
		case "S":
			return "Hỗ trợ";
		default:
			return "";
	}
};

export const numberWithCommas = (x) =>
	x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getObjJob = (record) => {
	const { typeJob } = record;
	let objJob = null;
	switch (typeJob) {
		case "S":
			objJob = record?.suddenJob;
			break;
		case "P":
			objJob = record?.periodicJob;
			break;
		case "C":
			objJob = record?.generalCleaningJob;
			break;
		case "O":
			objJob = record?.otherJob;
			break;
		default:
			break;
	}
	return objJob;
};

export const renderActiveAdmin = (role) => {
	if (Array.isArray(role) && role.includes(ADMINISTRATOR)) {
		return <CheckCircleFilled className="icon-admin" />;
	}
	return null;
};

export const getSrcAvatar = (avatar) => {
	if (!avatar) return null;

	return `${BASE_URL}${avatar}`;
};

export const menus = [
	{
		path: "/users",
		icon: <UserOutlined />,
		label: "Tài khoản",
		keyName: GENERAL,
		routes: [
			{
				path: "/administrators",
				label: "Quản trị viên",
				keyName: VIEW_ADMIN,
			},
			{ path: "/customers", label: "Người dùng", keyName: VIEW_CUSTOMER },
			{ path: "/staffs", label: "Nhân viên", keyName: VIEW_PARTNER },
			{ path: "/roles", label: "Vai trò", keyName: VIEW_ROLE },
		],
	},
	{
		path: "/services",
		icon: <InsertRowAboveOutlined />,
		label: "Dịch vụ",
		keyName: VIEW_SERVICE,
		routes: [
			{ path: "/services", label: "Dịch vụ", keyName: VIEW_SERVICE },
			{
				path: "/service-setting",
				label: "Chỉnh sửa giá",
				keyName: VIEW_SETTING,
			},
			{
				path: "/quotes-price",
				label: "Bảng báo giá dịch vụ",
				keyName: VIEW_SETTING,
			},
		],
	},
	{
		path: "/coupons",
		icon: <BarcodeOutlined />,
		label: "Khuyến mại",
		keyName: VIEW_VOUCHER,
		routes: [
			{ path: "/coupons", label: "Voucher", keyName: VIEW_VOUCHER },
			{
				path: "/referrals",
				label: "Quà giới thiệu",
				keyName: VIEW_REFERAL,
			},
		],
	},
	{
		path: "/notifications",
		icon: <BellOutlined />,
		label: "Thông báo",
		keyName: VIEW_NOTIFICATION,
	},
	{
		path: "/faqs",
		icon: <QuestionCircleOutlined />,
		label: "Câu hỏi thường gặp",
		keyName: VIEW_SETTING,
	},
	// {
	// 	path: "/complains",
	// 	icon: <SmileOutlined />,
	// 	label: "Khiếu nại, bảo hành",
	// 	keyName: VIEW_SETTING,
	// },
	{
		icon: <SettingOutlined />,
		label: "Mục giới thiệu",
		keyName: VIEW_SETTING,
		routes: [
			{
				path: "/educates",
				label: "Chương trình đào tạo",
				keyName: VIEW_SETTING,
			},
		],
	},
	{
		path: "/settings",
		icon: <SettingOutlined />,
		label: "Cài đặt",
		keyName: VIEW_SETTING,
		routes: [
			{
				path: "/settings/banner",
				label: "Banner",
				keyName: VIEW_SETTING,
			},
			{ path: "/settings/popup", label: "Popup", keyName: VIEW_SETTING },
			{
				path: "/settings/policy",
				label: "Pháp lý",
				keyName: VIEW_SETTING,
			},
			{
				path: "/settings/terms",
				label: "Cam kết",
				keyName: VIEW_SETTING,
			},
			{
				path: "/settings/contact",
				label: "Liên hệ",
				keyName: VIEW_SETTING,
			},
			{
				path: "/settings/payment-policy",
				label: "Chính sách thanh toán",
				keyName: VIEW_SETTING,
			},
			{
				path: "/settings/refund-policy",
				label: "Chính sách trả hàng",
				keyName: VIEW_SETTING,
			},
			{
				path: "/settings/rule-service",
				label: "Điều khoản ",
				keyName: VIEW_SETTING,
			},
			{
				path: "/settings/benefit",
				label: "Quyền và nghĩa vụ",
				keyName: VIEW_SETTING,
			},
		],
	},
];

export const camelCaseToNormal = (string) => {
	if (string) {
		return string
			.replace(/([A-Z])/g, " $1")
			.replace(/^./, (str) => str.toUpperCase());
	}
	return "";
};

export const moveItemInArray = (array, oldIndex, newIndex) => {
	if (newIndex >= array.length) {
		let k = newIndex - array.length + 1;
		// eslint-disable-next-line no-plusplus
		while (k--) {
			array.push(undefined);
		}
	}
	array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
	return array;
};

export const getRouteName = (routes = [], path = [], depth = 0) => {
	const routeName = [];
	if (routes.length && path.length) {
		const url = `/${path.slice(0, depth + 1).join("/")}`;
		const match = routes.find((x) => x.path === url.toLowerCase());
		if (!match) {
			return routeName;
		}
		routeName.push(match.name);
		if (match?.routes) {
			const child = getRouteName(match.routes, path, depth + 1);
			routeName.push(...child);
		}
	}
	return routeName;
};

export const queryUser = {
	page: 1,
	perPage: 10,
	scope: DEFAULT,
	orderBy: CREATE_AT,
	order: DESC,
};

export const getToken = () => `Bearer ${store.getState().auth?.token}`;

export const queryString = (params) =>
	Object.keys(params)
		.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
		.join("&");

export const typeGroupNotify = [
	{ value: "A", label: "Tất cả" },
	// { value: TOPIC, label: "Nhóm" },
	{ value: "U", label: "Người dùng" },
];

export const statusIncome = [
	{ key: "totalHours", label: "Giờ làm" },
	{ key: "totalInCome", label: "Thu nhập" },
	{ key: "totalShift", label: "Ca" },
	{ key: "customerFeeCash", label: "Phí khách hàng" },
	{ key: "advanceMoney", label: "Tiền tạm ứng" },
	{ key: "reward", label: "Thưởng" },
	{ key: "arrears", label: "Truy thu" },
	{ key: "payrollRetrospective", label: "Lương trả" },
];
