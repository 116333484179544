import {
	LOADING_ROLE, LOADING_ROLE_SUCCESS, LOADING_ROLE_FAIL,
	CREATE_ROLE, CREATE_ROLE_SUCCESS,
	EDIT_ROLE, EDIT_ROLE_SUCCESS,
	DELETE_ROLE, DELETE_ROLE_SUCCESS,
	LOADING_PERMISSION, LOADING_PERMISSION_SUCCESS, LOADING_PERMISSION_FAIL,
} from "@/const";

const initialState = {
	roles: [],
	permissions: [],
	error: "",
	isLoading: false,
};

export default function RoleReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_ROLE:
			return {
				...state,
				isLoading: true,
			};
		case LOADING_PERMISSION:
		case CREATE_ROLE:
		case EDIT_ROLE:
		case EDIT_ROLE_SUCCESS:
		case DELETE_ROLE:
		case DELETE_ROLE_SUCCESS:
			return {
				...state,
			};
		case LOADING_PERMISSION_SUCCESS:
			return {
				...state,
				permissions: action.data,
			};
		case LOADING_PERMISSION_FAIL:
			return {
				...state,
				permissions: [],
			};
		case LOADING_ROLE_SUCCESS:
			return {
				...state,
				roles: action.data,
				isLoading: false,
			};
		case LOADING_ROLE_FAIL:
			return {
				...state,
				roles: [],
				isLoading: false,
			};
		case CREATE_ROLE_SUCCESS:
			return {
				...state,
				roles: [...state.roles, action.data],
			};
		default:
			return state;
	}
}
