import "@/styles/styles.less";

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { IntlProvider } from "react-intl-redux";

import { PersistGate } from "redux-persist/integration/react";
import LoadingApp from "@components/Loading/LoadingApp";
import IntlGlobalProvider from "@/libs/IntlGlobal";
import { ConfigProvider } from "antd";
// import firebase from "@/libs/initFirebase";
import locale from "antd/lib/locale/vi_VN";
import store, { history, persistor } from "./configureStore";
import Routes from "./route";

// const worker = new Worker("assets/firebase-messaging-sw.js");
// const msg = firebase.messaging();

// msg.onMessage((payload) => {
// 	console.log("Message received. ", payload);
// 	// ...
// });

const App = () => {
	// msg.setBackgroundMessageHandler(async (remoteMessage) => {
	// 	console.log("remoteMessage", remoteMessage);
	// });
	useEffect(() => {
		// navigator.serviceWorker.register("../assets/firebase-messaging-sw.js")
		// 	.then((registration) => {
		// 		msg.useServiceWorker(registration);
		// 		msg.requestPermission().then(() => msg.getToken()).then((data) => {
		// 			localStorage.removeItem("deviceToken");
		// 			localStorage.setItem("deviceToken", data);
		// 			// console.log(data);
		// 		});
		// 	});
	});

	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				{(loaded, ...props) => (
					loaded && (
						<ConfigProvider locale={locale}>
							<LoadingApp loaded={loaded} {...props}>
								<IntlProvider>
									<IntlGlobalProvider>
										<ConnectedRouter history={history}>
											<Routes />
										</ConnectedRouter>
									</IntlGlobalProvider>
								</IntlProvider>
							</LoadingApp>
						</ConfigProvider>
					)
				)}
			</PersistGate>
		</Provider>
	);
};

ReactDOM.render(<App />, document.getElementById("app"));
if (process.env.NODE_ENV !== "production") {
	module.hot.accept();
}

document.getElementById("site-spinner").style.display = "none";
