import API from "@/libs/api";
import { queryString } from "@/libs/common";

const basePath = "/feedback";

export const deleteFeedback = (id) => API.delete(`${basePath}/${id}`);

export const getFeedback = (params) => API.get(`${basePath}/all?${queryString(params)}`);

export const getDetailFeedback = (id) => API.get(`${basePath}/${id}`);
