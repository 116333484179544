import {
	LOADING_COMPLAIN, LOADING_COMPLAIN_SUCCESS, LOADING_COMPLAIN_FAIL,
	DELETE_COMPLAIN, DELETE_COMPLAIN_SUCCESS,
} from "@/const";

const initialState = {
	complains: [],
	page: 1,
	perPage: 10,
	total: 0,
	isLoading: false,
};

export default function ComplainReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_COMPLAIN:
			return {
				...state,
				isLoading: true,
			};
		case DELETE_COMPLAIN:
			return {
				...state,
			};
		case LOADING_COMPLAIN_SUCCESS: {
			const {
				data, page, perPage, total,
			} = action.payload;
			return {
				...state,
				complains: data,
				page: Number(page),
				perPage: Number(perPage),
				total,
				isLoading: false,
			};
		}
		case LOADING_COMPLAIN_FAIL: {
			return {
				...state,
				page: Number(action.payload?.page) || 1,
				perPage: Number(action.payload?.perPage) || 10,
				total: action.payload?.total || 0,
				complains: [],
				isLoading: false,
			};
		}
		case DELETE_COMPLAIN_SUCCESS:
			return {
				...state,
				total: state.total - 1,
			};
		default:
			return state;
	}
}
