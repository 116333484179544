export const VIEW_SERVICE = "VIEW_SERVICE";
export const ADD_SERVICE = "ADD_SERVICE";

export const LOADING_SERVICE = "LOADING_SERVICE";
export const LOADING_SERVICE_SUCCESS = "LOADING_SERVICE_SUCCESS";
export const LOADING_SERVICE_FAIL = "LOADING_SERVICE_FAIL";

export const LOADING_FILTER_SERVICE = "LOADING_FILTER_SERVICE";
export const LOADING_FILTER_SERVICE_SUCCESS = "LOADING_FILTER_SERVICE_SUCCESS";

export const LOADING_SERVICE_CURRENT = "LOADING_SERVICE_CURRENT";
export const LOADING_SERVICE_CURRENT_SUCCESS = "LOADING_SERVICE_CURRENT_SUCCESS";
export const LOADING_SERVICE_CURRENT_FAIL = "LOADING_SERVICE_CURRENT_FAIL";

export const DELETE_SERVICE = "DELETE_SERVICE";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL";

export const VIEW_AUTOMATIC_SERVICE = "VIEW_AUTOMATIC_SERVICE";
export const ADD_AUTOMATIC_SERVICE = "ADD_AUTOMATIC_SERVICE";

export const LOADING_AUTOMATIC_SERVICE = "LOADING_AUTOMATIC_SERVICE";
export const LOADING_AUTOMATIC_SERVICE_SUCCESS = "LOADING_AUTOMATIC_SERVICE_SUCCESS";
export const LOADING_AUTOMATIC_SERVICE_FAIL = "LOADING_AUTOMATIC_SERVICE_FAIL";

export const LOADING_AUTOMATIC_SERVICE_CURRENT = "LOADING_AUTOMATIC_SERVICE_CURRENT";
export const LOADING_AUTOMATIC_SERVICE_CURRENT_SUCCESS = "LOADING_AUTOMATIC_SERVICE_CURRENT_SUCCESS";
export const LOADING_AUTOMATIC_SERVICE_CURRENT_FAIL = "LOADING_AUTOMATIC_SERVICE_CURRENT_FAIL";

export const EDIT_AUTOMATIC_SERVICE = "EDIT_AUTOMATIC_SERVICE";
export const EDIT_AUTOMATIC_SERVICE_SUCCESS = "EDIT_AUTOMATIC_SERVICE_SUCCESS";
export const EDIT_AUTOMATIC_SERVICE_FAIL = "EDIT_AUTOMATIC_SERVICE_FAIL";

export const DELETE_AUTOMATIC_SERVICE = "DELETE_AUTOMATIC_SERVICE";
export const DELETE_AUTOMATIC_SERVICE_SUCCESS = "DELETE_AUTOMATIC_SERVICE_SUCCESS";
export const DELETE_AUTOMATIC_SERVICE_FAIL = "DELETE_AUTOMATIC_SERVICE_FAIL";

export const DELETE_SERVICE_BATCH = "DELETE_SERVICE_BATCH";
export const DELETE_AUTOMATIC_SERVICE_BATCH = "DELETE_AUTOMATIC_SERVICE_BATCH";
