import React, { memo, forwardRef } from "react";
import { Button } from "antd";
import throttle from "lodash/throttle";

const SubmitButton = forwardRef((props, ref) => {
	const { children, onClick } = props;
	const onClickDelay = throttle(() => onClick && onClick(), 800, {
		trailing: false,
	});

	return (
		<Button ref={ref} {...props} onClick={onClickDelay}>
			{children}
		</Button>
	);
});

export default memo(SubmitButton);
