export const VIEW_FAQ = "VIEW_FAQ";
export const ADD_FAQ = "ADD_FAQ";

export const CREATE_FAQ = "CREATE_FAQ";
export const CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";
export const CREATE_FAQ_FAIL = "CREATE_FAQ_FAIL";

export const LOADING_FAQ = "LOADING_FAQ";
export const LOADING_FAQ_SUCCESS = "LOADING_FAQ_SUCCESS";
export const LOADING_FAQ_FAIL = "LOADING_FAQ_FAIL";

export const EDIT_FAQ = "EDIT_FAQ";
export const EDIT_FAQ_SUCCESS = "EDIT_FAQ_SUCCESS";
export const EDIT_FAQ_FAIL = "EDIT_FAQ_FAIL";

export const DELETE_FAQ = "DELETE_FAQ";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAIL = "DELETE_FAQ_FAIL";

export const DELETE_FAQ_BATCH = "DELETE_FAQ_BATCH";
