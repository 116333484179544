import {
	LOADING_NOTIFICATION, LOADING_NOTIFICATION_SUCCESS, LOADING_NOTIFICATION_FAIL,
	CREATE_NOTIFICATION, EDIT_NOTIFICATION, DELETE_NOTIFICATION, SEND_NOTIFICATION,
} from "@/const";
import { call, put, takeLatest } from "redux-saga/effects";
import {
	getNotificationTemplate, createNotification, updateNotificationTemplate,
	deleteNotificationTemplate, pushNotification,
} from "@/api/notification";
import { notification } from "antd";
import { history } from "@/configureStore";

function* fetchNotifications({ payload }) {
	try {
		const notiTemplate = yield call(getNotificationTemplate, payload);
		if (notiTemplate) {
			const { code, data } = notiTemplate;
			if (code === 200) {
				yield put({ type: LOADING_NOTIFICATION_SUCCESS, data });
			} else {
				yield put({ type: LOADING_NOTIFICATION_FAIL });
			}
		}
	} catch (error) {
		console.log(error);
	}
}

function* createNotificationTemplate({ payload }) {
	try {
		const create = yield call(createNotification, payload);

		if (create) {
			const { code, msg } = create;

			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else if (code === 417) {
				yield notification.error({
					// description: msg,
					message: "Thất bại",
				});
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Tạo khuyến mãi thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* editNotification({ payload }) {
	try {
		const { id, body } = payload;
		const edit = yield call(updateNotificationTemplate, id, body);

		if (edit) {
			const { code, msg } = edit;

			if (code === 200) {
				yield notification.success({
					description: msg,
					message: "Thành công",
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Sửa khuyến mãi thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* deleteNotification({ payload }) {
	try {
		const del = yield call(deleteNotificationTemplate, payload);
		if (del) {
			const { code, msg } = del;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
				yield history.goBack();
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Xóa khuyến mãi thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

function* sendNotification({ payload }) {
	try {
		const send = yield call(pushNotification, payload);
		if (send) {
			const { code, msg } = send;
			if (code === 200) {
				yield notification.success({
					message: "Thành công",
					description: msg,
				});
			} else {
				yield notification.error({
					description: msg,
					message: "Thất bại",
				});
			}
		}
	} catch (error) {
		yield notification.error({
			description: "Gửi khuyến mãi thất bại, thử lại sau !",
			message: "Thất bại",
		});
	}
}

export default function* notificationSaga() {
	yield takeLatest(LOADING_NOTIFICATION, fetchNotifications);
	yield takeLatest(CREATE_NOTIFICATION, createNotificationTemplate);
	yield takeLatest(EDIT_NOTIFICATION, editNotification);
	yield takeLatest(DELETE_NOTIFICATION, deleteNotification);
	yield takeLatest(SEND_NOTIFICATION, sendNotification);
}
