export const VIEW_COUPON = "VIEW_COUPON";
export const ADD_COUPON = "ADD_COUPON";
export const SEND_COUPON = "SEND_COUPON";

export const CREATE_COUPON = "CREATE_COUPON";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAIL = "CREATE_COUPON_FAIL";

export const LOADING_COUPON = "LOADING_COUPON";
export const LOADING_COUPON_SUCCESS = "LOADING_COUPON_SUCCESS";
export const LOADING_COUPON_FAIL = "LOADING_COUPON_FAIL";

export const EDIT_COUPON = "EDIT_COUPON";
export const EDIT_COUPON_SUCCESS = "EDIT_COUPON_SUCCESS";
export const EDIT_COUPON_FAIL = "EDIT_COUPON_FAIL";

export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL";

export const DELETE_COUPON_BATCH = "DELETE_COUPON_BATCH";
