export const VIEW_ROLE = "VIEW_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const ACTIVE_ROLE = "ACTIVE_ROLE";

export const LOADING_ROLE = "LOADING_ROLE";
export const LOADING_ROLE_SUCCESS = "LOADING_ROLE_SUCCESS";
export const LOADING_ROLE_FAIL = "LOADING_ROLE_FAIL";

export const LOADING_ROLE_CURRENT = "LOADING_ROLE_CURRENT";
export const LOADING_ROLE_CURRENT_SUCCESS = "LOADING_ROLE_CURRENT_SUCCESS";
export const LOADING_ROLE_CURRENT_FAIL = "LOADING_ROLE_CURRENT_FAIL";

export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL";

export const EDIT_ROLE = "EDIT_ROLE";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE_FAIL = "EDIT_ROLE_FAIL";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

export const LOADING_PERMISSION = "LOADING_PERMISSION";
export const LOADING_PERMISSION_SUCCESS = "LOADING_PERMISSION_SUCCESS";
export const LOADING_PERMISSION_FAIL = "LOADING_PERMISSION_FAIL";
