export const LOGGING_IN = "LOGGING_IN";
export const LOGGING_SUCCESS = "LOGGING_SUCCESS";
export const LOGGING_OUT = "LOGGING_OUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";

export const SCROLLING = "SCROLLING";
export const DISABLED_SCROLL = "DISABLED_SCROLL";

export const LOADING_PROFILE = "LOADING_PROFILE";
export const LOADING_PROFILE_SUCCESS = "LOADING_PROFILE_SUCCESS";
export const LOADING_PROFILE_FAIL = "LOADING_PROFILE_FAIL";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAIL = "EDIT_PROFILE_FAIL";

export const EDIT_ADMIN_USER = "EDIT_ADMIN_USER";
export const EDIT_ADMIN_USER_SUCCESS = "EDIT_ADMIN_USER_SUCCESS";
export const EDIT_ADMIN_USER_FAIL = "EDIT_ADMIN_USER_FAIL";

export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
