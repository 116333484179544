import {
	LOADING_HISTORY, LOADING_HISTORY_SUCCESS, LOADING_HISTORY_FAIL,
	DELETE_HISTORY, DELETE_HISTORY_SUCCESS,
} from "@/const";

const initialState = {
	histories: [],
	page: 1,
	perPage: 10,
	total: 0,
	isLoading: false,
};

export default function HistoryReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_HISTORY:
			return {
				...state,
				isLoading: true,
			};
		case DELETE_HISTORY:
			return {
				...state,
			};
		case LOADING_HISTORY_SUCCESS: {
			const {
				data, page, perPage, total,
			} = action.payload;
			return {
				...state,
				histories: data,
				page: Number(page),
				perPage: Number(perPage),
				total,
				isLoading: false,
			};
		}
		case LOADING_HISTORY_FAIL: {
			return {
				...state,
				page: Number(action.payload?.page) || 1,
				perPage: Number(action.payload?.perPage) || 10,
				total: action.payload?.total || 0,
				histories: [],
				isLoading: false,
			};
		}
		case DELETE_HISTORY_SUCCESS:
			return {
				...state,
				total: state.total - 1,
			};
		default:
			return state;
	}
}
