import {
	LOADING_CATEGORY, LOADING_CATEGORY_SUCCESS, LOADING_CATEGORY_FAIL, CREATE_CATEGORY,
	CREATE_CATEGORY_SUCCESS, EDIT_CATEGORY, EDIT_CATEGORY_SUCCESS,
	DELETE_CATEGORY, DELETE_CATEGORY_SUCCESS, CREATE_CATEGORY_FAIL,
} from "@/const";

const initialState = {
	categories: [],
	page: 1,
	perPage: 10,
	total: 0,
	isLoading: false,
};

export default function CategoryReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_CATEGORY:
			return {
				...state,
				isLoading: true,
			};
		case CREATE_CATEGORY:
		case EDIT_CATEGORY:
			return {
				...state,
				isLoading: true,
			};
		case EDIT_CATEGORY_SUCCESS:
		case DELETE_CATEGORY:
			return {
				...state,
			};
		case LOADING_CATEGORY_SUCCESS: {
			const {
				data, page, perPage, total,
			} = action.payload;
			return {
				...state,
				categories: data,
				page: Number(page),
				perPage: Number(perPage),
				total,
				isLoading: false,
			};
		}
		case LOADING_CATEGORY_FAIL: {
			return {
				...state,
				page: Number(action.payload?.page) || 1,
				perPage: Number(action.payload?.perPage) || 10,
				total: action.payload?.total || 0,
				categories: [],
				isLoading: false,
			};
		}
		case CREATE_CATEGORY_SUCCESS:
			return {
				...state,
				categories: [...state.categories, action.data],
			};
		case CREATE_CATEGORY_FAIL:
			return {
				...state,
			};
		case DELETE_CATEGORY_SUCCESS:
			return {
				...state,
				total: state.total - 1,
			};
		default:
			return state;
	}
}
