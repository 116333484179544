import API from "@/libs/api";
import { queryString } from "@/libs/common";

const basePath = "/account";

export const login = (data) => API.post(`${basePath}/login`, data);

export const getListGroupUser = () => API.get(`${basePath}/group-user`);

export const createManager = (data) => API.post(`${basePath}/create-manager`, data);

export const createAdmin = (data) => API.post(`${basePath}/create-admin`, data);

export const createSupporter = (data) => API.post(`${basePath}/create-supporter`, data);

export const changeStatusAccount = (data) => API.post(`${basePath}/change-status-account`, data);

export const changePassword = (data) => API.post(`${basePath}/change-password`, data);

export const requiredResetPassword = (data) => API.post(`${basePath}/require-reset-password`, data);

export const resetPassword = (data) => API.post(`${basePath}/reset-password`, data);

export const resetPasswordByAdmin = (data) => API.post(`${basePath}/reset-password-by-admin`, data);

export const newAccessToken = (data) => API.post(`${basePath}/new-access-token`, data);

export const listAccount = (params) => API.get(`${basePath}/list-accounts?${queryString(params)}`);

export const listPartner = (params) => API.get(`${basePath}/list-partners?${queryString(params)}`);

export const listCustomer = (params) => API.get(`${basePath}/list-customers?${queryString(params)}`);

export const logout = (data) => API.post(`${basePath}/logout`, data);

export const refreshToken = (data) => API.post(`${basePath}/new-access-token`, data);

export const info = () => API.get(`${basePath}/info`);

export const updateProfile = (data) => API.put(`${basePath}/update`, data);

export const editAdminUser = (id, data) => API.put(`${basePath}/update-admin/${id}`, data);

export const editPartnerUser = (id, data) => API.put(`${basePath}/update-partner/${id}`, data);

export const deleteAdminUser = (id) => API.delete(`${basePath}/delete-admin/${id}`);

export const getAccountDetail = (id) => API.get(`${basePath}/accounts/${id}`);

export const getPartnerDetail = (id) => API.get(`${basePath}/partners/${id}`);

export const getCustomerDetail = (id) => API.get(`${basePath}/customers/${id}`);

export const verifyPartner = (id, data) => API.put(`${basePath}/verify-partner/${id}`, data);

export const searchPartner = (params, data) => API.post(`${basePath}/partners-search?${queryString(params)}`, data);

export const searchCustomer = (params) => API.get(`${basePath}/search-customers?${queryString(params)}`);

export const chargePartnerHistory = (data) => API.post(`${basePath}/history-charge-partners`, data);

export const chargePartner = (data) => API.put(`${basePath}/charge-partner`, data);

export const minusChargePartner = (data) => API.put(`${basePath}/minus-charge-partner`, data);

export const addDeviceTokensAdmin = (data) => API.post(`${basePath}/add-device`, data);
