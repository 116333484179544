import {
	LOADING_CUSTOMER, LOADING_CUSTOMER_SUCCESS, LOADING_CUSTOMER_FAIL,
	LOADING_PARTNER, LOADING_PARTNER_SUCCESS, LOADING_PARTNER_FAIL,
	LOADING_ACCOUNT, LOADING_ACCOUNT_SUCCESS, LOADING_ACCOUNT_FAIL,
} from "@/const";

const initialState = {
	users: [],
	customers: [],
	partners: [],
	accounts: [],
	page: 1,
	perPage: 10,
	total: 0,
	isLoading: false,
};

export default function UserReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_CUSTOMER:
		case LOADING_PARTNER:
		case LOADING_ACCOUNT:
			return {
				...state,
				isLoading: true,
			};
		case LOADING_CUSTOMER_SUCCESS: {
			return {
				...state,
				...action.payload,
				customers: action.payload.data,
				isLoading: false,
			};
		}
		case LOADING_CUSTOMER_FAIL: {
			return {
				...state,
				...action.payload,
				customers: [],
				isLoading: false,
			};
		}
		case LOADING_PARTNER_SUCCESS: {
			return {
				...state,
				...action.payload,
				partners: action.payload.data,
				isLoading: false,
			};
		}
		case LOADING_PARTNER_FAIL: {
			return {
				...state,
				...action.payload,
				partners: [],
				isLoading: false,
			};
		}
		case LOADING_ACCOUNT_SUCCESS: {
			const { data } = action.payload;
			// const newAccounts = [];

			// if (Object.keys(data).length > 0) {
			// 	Object.entries(data).forEach(([key, value]) => {
			// 		if (Array.isArray(value)) {
			// 			value.forEach((val) => {
			// 				newAccounts.push({
			// 					...val,
			// 					type: key,
			// 				});
			// 			});
			// 		}
			// 	});
			// }

			return {
				...state,
				accounts: data,
				isLoading: false,
			};
		}
		case LOADING_ACCOUNT_FAIL: {
			return {
				...state,
				accounts: [],
				isLoading: false,
			};
		}
		default:
			return state;
	}
}
