export const VIEW_EDUCATE = "VIEW_EDUCATE";
export const ADD_EDUCATE = "ADD_EDUCATE";

export const CREATE_EDUCATE = "CREATE_EDUCATE";
export const CREATE_EDUCATE_SUCCESS = "CREATE_EDUCATE_SUCCESS";
export const CREATE_EDUCATE_FAIL = "CREATE_EDUCATE_FAIL";

export const LOADING_EDUCATE = "LOADING_EDUCATE";
export const LOADING_EDUCATE_SUCCESS = "LOADING_EDUCATE_SUCCESS";
export const LOADING_EDUCATE_FAIL = "LOADING_EDUCATE_FAIL";

export const EDIT_EDUCATE = "EDIT_EDUCATE";
export const EDIT_EDUCATE_SUCCESS = "EDIT_EDUCATE_SUCCESS";
export const EDIT_EDUCATE_FAIL = "EDIT_EDUCATE_FAIL";

export const DELETE_EDUCATE = "DELETE_EDUCATE";
export const DELETE_EDUCATE_SUCCESS = "DELETE_EDUCATE_SUCCESS";
export const DELETE_EDUCATE_FAIL = "DELETE_EDUCATE_FAIL";

export const DELETE_EDUCATE_BATCH = "DELETE_EDUCATE_BATCH";

// VIDEO
export const LOADING_VIDEO_CATEGORY = "LOADING_VIDEO_CATEGORY";
export const LOADING_VIDEO_CATEGORY_SUCCESS = "LOADING_VIDEO_CATEGORY_SUCCESS";
export const LOADING_VIDEO_CATEGORY_FAIL = "LOADING_VIDEO_CATEGORY_FAIL";

export const CREATE_VIDEO_CATEGORY = "CREATE_VIDEO_CATEGORY";
export const CREATE_VIDEO_CATEGORY_SUCCESS = "CREATE_VIDEO_CATEGORY_SUCCESS";
export const CREATE_VIDEO_CATEGORY_FAIL = "CREATE_VIDEO_CATEGORY_FAIL";

export const DELETE_VIDEO_CATEGORY = "DELETE_VIDEO_CATEGORY";
export const DELETE_VIDEO_CATEGORY_SUCCESS = "DELETE_VIDEO_CATEGORY_SUCCESS";
export const DELETE_VIDEO_CATEGORY_FAIL = "DELETE_VIDEO_CATEGORY_FAIL";

export const EDIT_VIDEO_CATEGORY = "EDIT_VIDEO_CATEGORY";
export const EDIT_VIDEO_CATEGORY_SUCCESS = "EDIT_VIDEO_CATEGORY_SUCCESS";
export const EDIT_VIDEO_CATEGORY_FAIL = "EDIT_VIDEO_CATEGORY_FAIL";
