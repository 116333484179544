import {
	LOADING_USER, EDIT_USER, DELETE_USER, DELETE_USER_BATCH,
	LOADING_CUSTOMER, LOADING_PARTNER, LOADING_ACCOUNT, CREATE_ACCOUNT,
	CHANGE_STATUS_ACCOUNT, CHANGE_PASSWORD, SEARCH_CUSTOMER, SEARCH_PARTNER,
	EDIT_PARTNER,
} from "@/const";

export const loadingUserAction = (payload) => ({
	type: LOADING_USER,
	payload,
});

export const editUserAction = (payload) => ({
	type: EDIT_USER,
	payload,
});

export const editPartnerAction = (payload, callback) => ({
	type: EDIT_PARTNER,
	payload,
	callback,
});

export const deleteUserAction = (payload) => ({
	type: DELETE_USER,
	payload,
});

export const deleteUserBatchAction = (payload) => ({
	type: DELETE_USER_BATCH,
	payload,
});

export const loadingCustomerAction = (payload) => ({
	type: LOADING_CUSTOMER,
	payload,
});

export const loadingPartnerAction = (payload) => ({
	type: LOADING_PARTNER,
	payload,
});

export const loadingAccountAction = (payload) => ({
	type: LOADING_ACCOUNT,
	payload,
});

export const createAccountAction = (payload) => ({
	type: CREATE_ACCOUNT,
	payload,
});

export const changeStatusAccountAction = (payload) => ({
	type: CHANGE_STATUS_ACCOUNT,
	payload,
});

export const changePasswordAction = (payload) => ({
	type: CHANGE_PASSWORD,
	payload,
});

export const searchCustomerAction = (payload) => ({
	type: SEARCH_CUSTOMER,
	payload,
});

export const searchPartnerAction = (payload) => ({
	type: SEARCH_PARTNER,
	payload,
});
