import {
	LOADING_SERVICE, LOADING_SERVICE_SUCCESS, LOADING_SERVICE_FAIL,
	LOADING_FILTER_SERVICE,
} from "@/const";
import { call, put, takeLatest } from "redux-saga/effects";
import { getListJob, searchListJob } from "@/api/services";

function* fetchServices({ payload }) {
	try {
		const services = yield call(getListJob, payload);
		const { code, data } = services;
		if (services) {
			if (code === 200) {
				yield put({
					type: LOADING_SERVICE_SUCCESS,
					payload: {
						data,
						page: payload.page,
						perPage: payload.limit,
						total: data?.pageInfo?.[0]?.total,
					},
				});
			} else {
				yield put({
					type: LOADING_SERVICE_FAIL,
					payload: {
						page: payload.page,
						perPage: payload.limit,
						total: 0,
					},
				});
			}
		}
	} catch (error) {
		console.log(error);
	}
}

function* fetchFilterServices({ payload }) {
	try {
		const services = yield call(searchListJob, payload);
		const { code, data } = services;
		if (services) {
			if (code === 200) {
				yield put({
					type: LOADING_SERVICE_SUCCESS,
					payload: {
						data,
						page: payload.page,
						perPage: payload.limit,
						total: data?.pageInfo?.[0]?.total,
					},
				});
			} else {
				yield put({
					type: LOADING_SERVICE_FAIL,
					payload: {
						page: payload.page,
						perPage: payload.limit,
						total: 0,
					},
				});
			}
		}
	} catch (error) {
		console.log(error);
	}
}

export default function* serviceSaga() {
	yield takeLatest(LOADING_SERVICE, fetchServices);
	yield takeLatest(LOADING_FILTER_SERVICE, fetchFilterServices);
}
