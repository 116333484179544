import {
	LOADING_ROLE, CREATE_ROLE, EDIT_ROLE, DELETE_ROLE, LOADING_PERMISSION, LOADING_ROLE_CURRENT,
} from "@/const";

export const loadingPermissionAction = (payload) => ({
	type: LOADING_PERMISSION,
	payload,
});

export const loadingRoleCurrentAction = (payload) => ({
	type: LOADING_ROLE_CURRENT,
	payload,
});

export const loadingRoleAction = (payload) => ({
	type: LOADING_ROLE,
	payload,
});

export const createRoleAction = (payload) => ({
	type: CREATE_ROLE,
	payload,
});

export const editRoleAction = (payload) => ({
	type: EDIT_ROLE,
	payload,
});

export const deleteRoleAction = (payload) => ({
	type: DELETE_ROLE,
	payload,
});
