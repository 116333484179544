import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "@/layouts";
import loadable from "@/libs/loadable";
import routeConfig from "./routeConfig";
import ProtectedRoute from "./ProtectedRoute";

const Login = loadable(() => import("@/pages/Login"));

const Routes = () => (
	<Switch>
		<Route path="/login" component={Login} />
		<Route>
			<Layout>
				<Switch>
					{routeConfig.map((route, i) => (
						<ProtectedRoute key={i} {...route} />
					))}
				</Switch>
			</Layout>
		</Route>
	</Switch>
);

export default Routes;
