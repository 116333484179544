import {
	DELETE_COMPLAIN,
	DELETE_COMPLAIN_SUCCESS,
	LOADING_COMPLAIN, LOADING_COMPLAIN_FAIL, LOADING_COMPLAIN_SUCCESS,
} from "@/const";
import { call, put, takeLatest } from "redux-saga/effects";
import {
	deleteFeedback,
	getFeedback,
} from "@/api/complain";
import { notification } from "antd";
import { history } from "@/configureStore";

function* fetchComplains({ payload }) {
	try {
		const complains = yield call(getFeedback, payload);
		if (complains) {
			const { code, data } = complains;
			// console.log(complains);
			if (code === 200) {
				yield put({
					type: LOADING_COMPLAIN_SUCCESS,
					payload: {
						data: data?.feedbacks,
						page: payload.page,
						perPage: payload.limit,
						total: data?.total,
					},
				});
			} else {
				yield put({
					type: LOADING_COMPLAIN_FAIL,
					payload: {
						page: payload.page,
						perPage: payload.limit,
						total: 0,
					},
				});
			}
		}
	} catch (error) {
		console.log(error);
	}
}

function* deleteComplain({ payload }) {
	try {
		const remove = yield call(deleteFeedback, payload);
		if (remove) {
			const { code } = remove;
			if (code === 200) {
				yield put({ type: DELETE_COMPLAIN_SUCCESS });
				yield notification.success({ message: "Xóa khiếu nại thành công" });
				yield history.push("/complains");
			} else {
				yield notification.error({ message: "Xóa khiếu nại thất bại" });
			}
		}
	} catch (error) {
		console.log(error);
	}
}

export default function* complainSaga() {
	yield takeLatest(LOADING_COMPLAIN, fetchComplains);
	yield takeLatest(DELETE_COMPLAIN, deleteComplain);
}
