import {
	LOADING_INTRODUCTION, LOADING_INTRODUCTION_SUCCESS, LOADING_INTRODUCTION_FAIL,
	CREATE_INTRODUCTION, CREATE_INTRODUCTION_SUCCESS,
	EDIT_INTRODUCTION, EDIT_INTRODUCTION_SUCCESS,
	DELETE_INTRODUCTION, DELETE_INTRODUCTION_SUCCESS,
} from "@/const";

const initialState = {
	introduction: [],
	page: 1,
	perPage: 10,
	total: 0,
	isLoading: false,
};

export default function IntroductionReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_INTRODUCTION:
			return {
				...state,
				isLoading: true,
			};
		case CREATE_INTRODUCTION:
		case EDIT_INTRODUCTION:
		case EDIT_INTRODUCTION_SUCCESS:
		case DELETE_INTRODUCTION:
			return {
				...state,
			};
		case LOADING_INTRODUCTION_SUCCESS: {
			const {
				data, page, perPage, total,
			} = action.payload;
			return {
				...state,
				introduction: data,
				page: Number(page),
				perPage: Number(perPage),
				total,
				isLoading: false,
			};
		}
		case LOADING_INTRODUCTION_FAIL: {
			return {
				...state,
				page: Number(action.payload?.page) || 1,
				perPage: Number(action.payload?.perPage) || 10,
				total: action.payload?.total || 0,
				introduction: [],
				isLoading: false,
			};
		}
		case CREATE_INTRODUCTION_SUCCESS:
			return {
				...state,
				introduction: [...state.introduction, action.data],
			};
		case DELETE_INTRODUCTION_SUCCESS:
			return {
				...state,
				total: state.total - 1,
			};
		default:
			return state;
	}
}
