import {
	LOADING_COUPON, LOADING_COUPON_SUCCESS, LOADING_COUPON_FAIL,
} from "@/const";

const initialState = {
	coupons: [],
	isLoading: false,
};

export default function CouponReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_COUPON:
			return {
				...state,
				isLoading: true,
			};
		case LOADING_COUPON_SUCCESS: {
			return {
				...state,
				coupons: action.data,
				isLoading: false,
			};
		}
		case LOADING_COUPON_FAIL: {
			return {
				...state,
				coupons: [],
				isLoading: false,
			};
		}
		default:
			return state;
	}
}
