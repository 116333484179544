import { GENERAL } from "@/const";
import loadable from "@/libs/loadable";

/**
 * User
 */
const ListUser = loadable(() => import("@/pages/User/ListUser"));
const ListAccount = loadable(() => import("@/pages/User/ListAccount"));
const CreateUser = loadable(() => import("@/pages/User/CreateUser"));
const DetailUser = loadable(() => import("@/pages/User/DetailUser"));
const DetailStaff = loadable(() => import("@/pages/User/DetailUser"));
const Refered = loadable(() => import("@/pages/User/components/Refered"));

/**
 * Notification
 */
const ListNotification = loadable(() => import("@/pages/Notification/ListNotification"));
const CreateNotification = loadable(() => import("@/pages/Notification/CreateNotification"));
const DetailNotification = loadable(() => import("@/pages/Notification/DetailNotification"));
const GroupNotification = loadable(() => import("@/pages/Notification/GroupNotification"));
const SubscribeNotification = loadable(() => import("@/pages/Notification/SubscribeNotification"));

/**
 * Role
 */
const ListRole = loadable(() => import("@/pages/Role/ListRole"));
const CreateRole = loadable(() => import("@/pages/Role/CreateRole"));
const DetailRole = loadable(() => import("@/pages/Role/DetailRole"));

/**
 * Home
 */
const Home = loadable(() => import("@/pages/Home"));
const NotFound = loadable(() => import("@/components/NotFound"));

/**
 * Setting
 */
const Banner = loadable(() => import("@/pages/Setting/Banner"));
const Popup = loadable(() => import("@/pages/Setting/Popup"));
const Policy = loadable(() => import("@/pages/Setting/Policy"));
const Terms = loadable(() => import("@/pages/Setting/Terms"));
const Contact = loadable(() => import("@/pages/Setting/Contact"));
const PriceConfig = loadable(() => import("@/pages/Setting/PriceConfig"));
const QuotesPrice = loadable(() => import("@/pages/Setting/QuotesPrice"));
const Benefit = loadable(() => import("@/pages/Setting/Benefit"));
const PaymentPolicy = loadable(() => import("@/pages/Setting/PaymentPolicy"));
const RefundPolicy = loadable(() => import("@/pages/Setting/RefundPolicy"));
const RuleService = loadable(() => import("@/pages/Setting/RuleService"));

/**
 * Payment
 */
const ListPayment = loadable(() => import("@/pages/Payment/ListPayment"));
const DetailPayment = loadable(() => import("@/pages/Payment/DetailPayment"));

/**
 * Service
 */
const ListService = loadable(() => import("@/pages/Service/ListService"));
const DetailService = loadable(() => import("@/pages/Service/DetailService"));

/**
 * Automatic
 */
// const ListAutomatic = loadable(() => import("@/pages/AutomaticService/ListAutomatic"));
const DetailAutomatic = loadable(() => import("@/pages/AutomaticService/DetailAutomatic"));
const CreateSurvey = loadable(() => import("@/pages/AutomaticService/CreateSurvey"));
const CreateJob = loadable(() => import("@/pages/AutomaticService/CreateJob"));
const CreateAutomatic = loadable(() => import("@/pages/AutomaticService/CreateAutomatic"));

/**
 * Coupon
 */
const ListCoupon = loadable(() => import("@/pages/Coupon/ListCoupon"));
const CreateCoupon = loadable(() => import("@/pages/Coupon/CreateCoupon"));
const DetailCoupon = loadable(() => import("@/pages/Coupon/DetailCoupon"));
const SettingCoupon = loadable(() => import("@/pages/Coupon/SettingCoupon"));
const ListRewardRef = loadable(() => import("@/pages/Coupon/ListRewardRef"));

/**
 * Faq
 */
const ListFaq = loadable(() => import("@/pages/Faq/ListFaq"));
const CreateFaq = loadable(() => import("@/pages/Faq/CreateFaq"));
const DetailFaq = loadable(() => import("@/pages/Faq/DetailFaq"));

/**
 * Complain
 */
const ListComplain = loadable(() => import("@/pages/Complain/ListComplain"));
const DetailComplain = loadable(() => import("@/pages/Complain/DetailComplain"));

/**
 * Educate program
 */
const ListEducate = loadable(() => import("@/pages/Educate/ListEducate"));
const CreateEducate = loadable(() => import("@/pages/Educate/CreateEducate"));
const DetailEducate = loadable(() => import("@/pages/Educate/DetailEducate"));
/**
 * Withdrawal
 */
const ListWithdrawal = loadable(() => import("@/pages/Withdrawal/ListWithdrawal"));
const DetailWithdrawal = loadable(() => import("@/pages/Withdrawal/DetailWithdrawal"));

/**
 * History
 */
const ListHistory = loadable(() => import("@/pages/History/ListHistory"));
const DetailHistory = loadable(() => import("@/pages/History/DetailHistory"));

/**
 * Introduction
 */
const ListIntroduction = loadable(() => import("@/pages/Introduction/ListIntroduction"));
const CreateIntroduction = loadable(() => import("@/pages/Introduction/CreateIntroduction"));

/**
 * Partner
 */
// const ListPartner = loadable(() => import("@/pages/Partner/ListPartner"));
// const CreatePartner = loadable(() => import("@/pages/Partner/CreatePartner"));

/**
 * Category
 */
const ListCategory = loadable(() => import("@/pages/Category/ListCategory"));
const DetailCategory = loadable(() => import("@/pages/Category/DetailCategory"));
const CreateCategory = loadable(() => import("@/pages/Category/CreateCategory"));

const commonRoute = {
	exact: true,
	strict: true,
};

const routeConfig = [
	{
		path: "/",
		name: "Trang chủ",
		component: Home,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/customers",
		name: "Người dùng",
		component: ListUser,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/administrators",
		name: "Quản trị viên",
		component: ListAccount,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/staffs",
		name: "Nhân viên",
		component: ListUser,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/user-create",
		name: "Tạo tài khoản",
		component: CreateUser,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/administrator/:id",
		name: "Chi tiết tài khoản",
		component: DetailUser,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/customer/:id",
		name: "Chi tiết tài khoản",
		component: DetailUser,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/staff/:id",
		name: "Chi tiết tài khoản",
		component: DetailStaff,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/profile",
		name: "Thông tin cá nhân",
		component: DetailUser,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/users/refered/:id",
		name: "Mời",
		component: Refered,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/notifications",
		name: "Thông báo",
		component: ListNotification,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/notification-create",
		name: "Tạo thông báo",
		component: CreateNotification,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/notification-group",
		name: "Nhóm thông báo",
		component: GroupNotification,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/notification-group/:topic",
		name: "Đăng ký thông báo",
		component: SubscribeNotification,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/notifications/:id",
		name: "Chi tiết thông báo",
		component: DetailNotification,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/roles",
		name: "Vai trò",
		component: ListRole,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/role-create",
		name: "Tạo vai trò",
		component: CreateRole,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/roles/:id",
		name: "Chi tiết vai trò",
		component: DetailRole,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/payments",
		name: "Thanh toán",
		component: ListPayment,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/payments/:id",
		name: "Thanh toán",
		component: DetailPayment,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/services",
		name: "Dịch vụ",
		component: ListService,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/services/:id",
		name: "Dịch vụ",
		component: DetailService,
		keyName: GENERAL,
		...commonRoute,
	},
	// {
	// 	path: "/automatic-services",
	// 	name: "Dịch vụ tự động",
	// 	component: ListAutomatic,
	// 	keyName: GENERAL,
	// 	...commonRoute,
	// },
	{
		path: "/automatic-services/:id",
		name: "Dịch vụ tự động",
		component: DetailAutomatic,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/automatic-job-create",
		name: "Tạo dịch vụ",
		component: CreateJob,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/automatic-service-create",
		name: "Tạo dịch vụ tự động",
		component: CreateAutomatic,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/survey-create",
		name: "Tạo khảo sát",
		component: CreateSurvey,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/coupons",
		name: "Khuyến mãi",
		component: ListCoupon,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/referrals",
		name: "Quà giới thiệu",
		component: ListRewardRef,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/coupons/:id",
		name: "Chi tiết khuyến mãi",
		component: DetailCoupon,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/coupon-create",
		name: "Tạo khuyến mãi",
		component: CreateCoupon,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/coupons-setting",
		name: "Tùy chọn khuyến mãi",
		component: SettingCoupon,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/service-setting",
		name: "Chỉnh sửa giá",
		component: PriceConfig,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/quotes-price",
		name: "Bảng báo giá",
		component: QuotesPrice,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/faqs",
		name: "Câu hỏi thường gặp",
		component: ListFaq,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/faqs/:id",
		name: "Chi tiết câu hỏi thường gặp",
		component: DetailFaq,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/faq-create",
		name: "Tạo câu hỏi thường gặp",
		component: CreateFaq,
		keyName: GENERAL,
		...commonRoute,
	},
	// {
	// 	path: "/partners",
	// 	name: "Đối tác thanh toán",
	// 	component: ListPartner,
	// 	keyName: GENERAL,
	// 	...commonRoute,
	// },
	// {
	// 	path: "/partner-create",
	// 	name: "Tạo đối tác thanh toán",
	// 	component: CreatePartner,
	// 	keyName: GENERAL,
	// 	...commonRoute,
	// },
	{
		path: "/categories",
		name: "Danh mục",
		component: ListCategory,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/category-create",
		name: "Tạo danh mục",
		component: CreateCategory,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/category-detail/:id",
		name: "Tạo danh mục",
		component: DetailCategory,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/introduction",
		name: "Thông tin giới thiệu",
		component: ListIntroduction,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/introduction-create",
		name: "Tạo thông tin giới thiệu",
		component: CreateIntroduction,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/complains",
		name: "Khiếu nại, bảo hành",
		component: ListComplain,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/complains/:id",
		name: "Khiếu nại, bảo hành",
		component: DetailComplain,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/educates",
		name: "Chương trình đào tạo",
		component: ListEducate,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/educates/:id",
		name: "Chi tiết chương trình đào tạo",
		component: DetailEducate,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/educate-create",
		name: "Chương trình đào tạo",
		component: CreateEducate,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/withdrawals",
		name: "Nạp - rút tiền",
		component: ListWithdrawal,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/withdrawals/:id",
		name: "Nạp - rút tiền",
		component: DetailWithdrawal,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/histories",
		name: "Lịch sử sử dụng",
		component: ListHistory,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/histories/:id",
		name: "Lịch sử sử dụng",
		component: DetailHistory,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/settings/banner",
		name: "Banner",
		component: Banner,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/settings/popup",
		name: "Popup",
		component: Popup,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/settings/policy",
		name: "Pháp lý",
		component: Policy,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/settings/terms",
		name: "Cam kết",
		component: Terms,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/settings/contact",
		name: "Liên hệ",
		component: Contact,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/settings/payment-policy",
		name: "Chính sách thanh toán",
		component: PaymentPolicy,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/settings/refund-policy",
		name: "Chính sách trả hàng",
		component: RefundPolicy,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/settings/rule-service",
		name: "Điều khoản dịch vụ",
		component: RuleService,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "/settings/benefit",
		name: "Quyền và nghĩa vụ",
		component: Benefit,
		keyName: GENERAL,
		...commonRoute,
	},
	{
		path: "*",
		name: "Không tìm thấy",
		component: NotFound,
		keyName: GENERAL,
	},
];

export default routeConfig;
