import React, { memo, useState } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import LoginOutlined from "@ant-design/icons/LoginOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { history } from "@/configureStore";
import { useDispatch } from "react-redux";
import { logoutAction } from "@/actions/auth";
import { renderActiveAdmin } from "@/libs/common";
import ChangePassword from "@/pages/User/components/ChangePassword";
import { LockOutlined } from "@ant-design/icons";

const MenuUser = ({ user }) => {
	const dispatch = useDispatch();

	const [visibleChangePw, setVisibleChangePw] = useState(false);

	const showChangePw = () => setVisibleChangePw(true);

	const onCloseChangePw = () => setVisibleChangePw(false);

	const navigateProfile = () => history.push("/profile");

	const logout = () => dispatch(logoutAction());

	const getName = () => user?.fullName || user?.username || user?.email || "";

	const menu = () => (
		<Menu>
			<Menu.Item key="user" onClick={navigateProfile} className="mi-name">
				<UserOutlined />
				{getName()}
			</Menu.Item>

			<Menu.Divider />

			<Menu.Item key="change-pwd" onClick={showChangePw}>
				<LockOutlined />
				Đổi mật khẩu
			</Menu.Item>

			<Menu.Divider />

			<Menu.Item key="logout" onClick={logout}>
				<LoginOutlined />
				Đăng xuất
			</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<ChangePassword
				visibleChangePw={visibleChangePw}
				onCloseChangePw={onCloseChangePw}
			/>

			<Dropdown
				overlay={menu}
				className="header__menu-user"
				placement="bottomRight"
			>
				<div>
					<Avatar className="avatar" icon={<UserOutlined />} src={user?.avatar} size={25} />
					<div className="text-hello">
						<span>{`Xin chào, ${getName()}`}</span>
					</div>
					{renderActiveAdmin(user?.roles)}
				</div>
			</Dropdown>
		</div>

	);
};

export default memo(MenuUser);
