import {
	LOGGING_IN, LOGGING_OUT, SCROLLING, DISABLED_SCROLL,
	LOADING_PROFILE, EDIT_PROFILE, UPLOAD_AVATAR, REFRESH_TOKEN, EDIT_ADMIN_USER,
} from "@/const";

export const loginAction = (payload) => ({
	type: LOGGING_IN,
	payload,
});

export const logoutAction = () => ({
	type: LOGGING_OUT,
});

export const scrollingAction = () => ({
	type: SCROLLING,
});

export const disabledScrollAction = () => ({
	type: DISABLED_SCROLL,
});

export const fetchProfileAction = (payload) => ({
	type: LOADING_PROFILE,
	payload,
});

export const editProfileAction = (payload) => ({
	type: EDIT_PROFILE,
	payload,
});

export const uploadAvatarAction = (payload) => ({
	type: UPLOAD_AVATAR,
	payload,
});

export const refreshTokenAction = (payload) => ({
	type: REFRESH_TOKEN,
	payload,
});
