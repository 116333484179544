import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import AccessDeny from "@components/AccessDeny";

const ProtectedRoute = ({
	exact, strict, path, routes, keyName, component: Component,
}) => {
	const { isLoggedIn, permissions } = useSelector((state) => state.auth);

	const checkPermissions = (props) => {
		if (permissions.includes(keyName)) {
			return <Component {...props} routes={routes} />;
		}
		return <AccessDeny />;
	};

	return (
		<Route
			path={path}
			exact={exact || false}
			strict={strict || false}
			render={(props) => (
				isLoggedIn
					? checkPermissions(props)
					: (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: props.location },
							}}
						/>
					)
			)}
		/>
	);
};

export default ProtectedRoute;
