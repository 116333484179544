import {
	LOADING_PAYMENT, LOADING_PAYMENT_SUCCESS, LOADING_PAYMENT_FAIL,
	DELETE_PAYMENT, DELETE_PAYMENT_SUCCESS,
} from "@/const";

const initialState = {
	payments: [],
	error: "",
	isLoading: false,
};

export default function PaymentReducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_PAYMENT:
			return {
				...state,
				isLoading: true,
			};
		case DELETE_PAYMENT:
		case DELETE_PAYMENT_SUCCESS:
			return {
				...state,
			};
		case LOADING_PAYMENT_SUCCESS:
			return {
				...state,
				payments: action.data,
				isLoading: false,
			};
		case LOADING_PAYMENT_FAIL:
			return {
				...state,
				payments: [],
				isLoading: false,
			};
		default:
			return state;
	}
}
